import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import * as actions from './orders.actions';
import { useOrdersSelectors } from './orders.selectors';

export function useOrdersFacade() {
  const dispatch = useDispatch();
  const selectors = useOrdersSelectors();

  // Getters with selectors
  const ordersTotal         = selectors.selectOrdersTotal;
  const ordersPageData      = selectors.selectOrdersPageData;
  const ordersPageDraw      = selectors.selectOrdersPageDraw;
  const ordersCurrentPage   = selectors.selectOrdersPagePage;

  const orderDetailProductsPageData          = selectors.selectOrderDetailProductsPageData;
  const orderDetailProductsPageDraw          = selectors.selectOrderDetailProductsPageDraw;
  const orderDetailProductsPagePage          = selectors.selectOrderDetailProductsPagePage;
  const orderDetailProductsTotal             = selectors.selectOrderDetailProductsTotal;
  const orderDetailProductsRelatedStoreCards = selectors.selectOrderDetailRelatedStoreCards;

  const selectedOrder                    = selectors.selectSelectedOrder;
  const selectedOrderFirm                = selectors.selectSelectedOrderFirm;
  const selectedOrderPartnerMessages     = selectors.selectSelectedOrderPartnerMessages;
  const selectedOrderAddresses           = selectors.selectSelectedOrderAddresses;
  const selectedOrderDeliveryList        = selectors.selectSelectedOrderDeliveryList;
  const selectedOrderBalikobotStatusLogs = selectors.selectSelectedOrderBalikobotStatusLogs;
  const selectedOrderAbraCommands        = selectors.selectSelectedOrderAbraCommands;
  const selectedOrderAbraCommandsErrors  = selectors.selectSelectedOrderAbraCommandsErrors;
  const selectedOrderSubscriptions       = selectors.selectSelectedOrderSubscriptions;

  const transportationTypes           = selectors.selectTransportationTypes;
  const transportationTypesDraftValid = selectors.selectTransportationTypesDraftValid;
  const stavObjednavky                = selectors.selectStavObjednavky;
  const ordersProductsPdfList         = selectors.selectOrdersProductsPdfList;
  const ordersFirmsPdfList            = selectors.selectOrdersFirmsPdfList;
  const ordersStoreCardsPdfList       = selectors.selectOrdersStoreCardsList;
  const ordersResMessage              = selectors.selectResMessage;
  const orderPartners                 = selectors.selectPartners;
  const selectedOrderSyncStatuses     = selectors.selectSelectedOrderSyncStatuses;
  const ordersGeneratedPdfDocument    = selectors.selectGeneratedPdfDocument;
  const redOrderStateFoldersCounts    = selectors.selectRedOrderStateFoldersCounts;
  const queuedCollectionLists         = selectors.selectQueuedCollectionLists;
  const balikobotOrderEntry           = selectors.selectBalikobotOrderEntry;
  const balikobotOrderBranch          = selectors.selectBalikobotOrderBranch;
  const branchesByShipperId           = selectors.selectBranchesByShipperId;
  const serviceTypesByShipperId       = selectors.selectServiceTypesByShipperId;
  const ordersCountByEmail            = selectors.selectOrdersCountByEmail;
  const subscriptionsList             = selectors.selectSubscriptions;
  const sourceSubscriptionsByIndex    = selectors.selectSourceSubscriptionsByIndex;
  const selectedOrderAddresses_NEW_VERSION = selectors.selectSelectedOrderAddresses_NEW_VERSION;

  // Setters and fetching with reducer
  const fetchOrdersPageData = useCallback(
    (
      draw=1,
      page=0,
      orderStateId=null,
      transportationTypeId=null,
      type=null,
      search=null,
    ) =>
      dispatch(actions.ordersListPageRequest(draw, page, orderStateId, transportationTypeId, type, search)),
    [dispatch]
  );

  const fetchTransportationTypes = useCallback(
    () =>
      dispatch(actions.ordersTransportationTypesRequest()),
    [dispatch]
  );

  const fetchStavObjednavky = useCallback(
    () =>
      dispatch(actions.ordersStavObjednavkyRequest()),
    [dispatch]
  );

  const fetchOrderDetailProductsPageData = useCallback(
    (orderId, draw=1, page=0) =>
      dispatch(actions.ordersOrderDetailProductsPageRequest(orderId, draw, page)),
    [dispatch]
  );

  const fetchSelectedOrderData = useCallback(
    orderId =>
      dispatch(actions.ordersSelectedOrderRequest(orderId)),
    [dispatch]
  );

  const updateOrderOrdersState = useCallback(
    (orderId, ordersStateId, sessionId) =>
      dispatch(actions.ordersUpdateStavObjednavkyRequest(
        orderId, ordersStateId, sessionId
      )),
    [dispatch]
  );

  const fetchSelectedOrderFirm = useCallback(
    abraId =>
      dispatch(actions.ordersFirmRequest(abraId)),
    [dispatch]
  );

  const nullifySelectedOrderFirm = useCallback(
    () =>
      dispatch(actions.ordersFirmReset()),
    [dispatch]
  );

  const fetchOrdersProductsListForPdfList = useCallback(
    (ordersIds, ordersFirmIds) =>
      dispatch(actions.ordersOrderProductsForPdfListRequest(ordersIds, ordersFirmIds)),
    [dispatch]
  );

  const nullifyOrdersProductsListForPdfList = useCallback(
    () =>
      dispatch(actions.ordersOrderProductsForPdfListReset()),
    [dispatch]
  );

  const sendOrderToStavEmail = useCallback(
    (orderId, stavObjednavkyId) =>
      dispatch(actions.ordersOrderToStavSendEmailRequest(
        orderId, stavObjednavkyId
      )),
    [dispatch]
  );

  const sendOrderToStavSms = useCallback(
    (orderId, stavObjednavkyId) =>
      dispatch(actions.ordersOrderToStavSendSmsRequest(
        orderId, stavObjednavkyId
      )),
    [dispatch]
  );

  const ordersResetResponseMessage = useCallback(
    () =>
      dispatch(actions.ordersResponseMessageReset()),
    [dispatch]
  );

  const fetchSelectedOrderPartnerMessages = useCallback(
    orderId =>
      dispatch(actions.ordersPartnerMessagesRequest(orderId)),
    [dispatch]
  );

  const updateOrderItemQuantity = useCallback(
    (orderItemId, quantity, ident) =>
      dispatch(actions.ordersUpdateOrderItemQuantityRequest(
        orderItemId, quantity, ident
      )),
    [dispatch]
  );

  const fetchSelectedOrderAddresses = useCallback(
    (firmAbraId, firmOfficeAbraId) =>
      dispatch(actions.ordersOrderAddressesRequest(firmAbraId, firmOfficeAbraId)),
    [dispatch]
  );

  const fetchOrderPartner = useCallback(
    (partnerId) =>
      dispatch(actions.ordersPartnerRequest(partnerId)),
    [dispatch]
  );

  const orderPartnersCloseStream = useCallback(
    () =>
      dispatch(actions.ordersPartnerCloseStream()),
    [dispatch]
  );

  const deleteOrderProduct = useCallback(
    (productId, ident) =>
      dispatch(actions.ordersOrderItemDeleteRequest(productId, ident)),
    [dispatch]
  );

  const createNewPartnerMessage = useCallback(
    (orderId, authorPartnerId, message) =>
      dispatch(actions.ordersNewPartnerMessageRequest(orderId, authorPartnerId, message)),
    [dispatch]
  );

  const syncOrderWithAbra = useCallback(
    (orderExternalNumber) =>
      dispatch(actions.ordersOrderSyncAbraRequest(orderExternalNumber)),
    [dispatch]
  );

  const createOrderDeliveryList = useCallback(
    (orderId, ident) =>
      dispatch(actions.ordersDeliveryListCreateRequest(orderId, ident)),
    [dispatch]
  );

  const recreateOrderDeliveryList = useCallback(
    (deliveryListId) =>
      dispatch(actions.ordersDeliveryListRecreateRequest(deliveryListId)),
    [dispatch]
  );

  const fetchOrderDeliveryList = useCallback(
    (orderId) =>
      dispatch(actions.ordersOrderDeliveryListRequest(orderId)),
    [dispatch]
  );

  const createOrderItem = useCallback(
    (sessionId, userUid, orderId, storeCardId, quantity, rowtype=3) =>
      dispatch(actions.ordersAddOrderItemRequest(
        sessionId, userUid, orderId, storeCardId, quantity, rowtype
      )),
    [dispatch]
  );

  const checkOrderSyncStatuses = useCallback(
    (externalNumber) =>
      dispatch(actions.ordersOrderSyncStatusesCheckRequest(externalNumber)),
    [dispatch]
  );

  // Sometimes, after abra sync, abraId is still not assigned, handled this way
  const syncWithAbraWhileInAbra = useCallback(
    (externalNumber) =>
      dispatch(actions.ordersOrderSyncWithAbraWhileInAbraRequest(externalNumber)),
    [dispatch]
  );

  const printOrdersDocument = useCallback(
    (type, itemId, userUid, ident) =>
      dispatch(actions.ordersPrintDocumentRequest(type, itemId, userUid, ident)),
    [dispatch]
  );

  const printOrderBalikobot = useCallback(
    (orderId, userUid, ident) =>
      dispatch(actions.ordersPrintBalikobotRequest(orderId, userUid, ident)),
    [dispatch]
  );

  const printOrderThermal80 = useCallback(
    (type, itemId, userUid, ident) =>
      dispatch(actions.ordersPrintThermal80Request(type, itemId, userUid, ident)),
    [dispatch]
  );

  // itemIds is string od ids separated by comma
  const massPrintOrdersDocuments = useCallback(
    (type, itemIds, userUid, ident) =>
      dispatch(actions.ordersMassPrintDocumentsRequest(type, itemIds, userUid, ident)),
    [dispatch]
  );

  const generateOrdersTypePdfDocument = useCallback(
    (type, itemId, userUid, ident) =>
      dispatch(actions.ordersGeneratePdfDocumentRequest(type, itemId, userUid, ident)),
    [dispatch]
  );

  const massGenerateOrdersTypePdfDocument = useCallback(
    (type, itemIds, userUid, ident) =>
      dispatch(actions.ordersMassGeneratePdfDocumentRequest(type, itemIds, userUid, ident)),
    [dispatch]
  );

  const resetOrdersGeneratedPdf = useCallback(
    () =>
      dispatch(actions.ordersResetGeneratedPdf()),
    [dispatch]
  );

  const updateBulkOrdersOrdersState = useCallback(
    (ordersIds, ordersStateId, sessionId) =>
      dispatch(actions.ordersBulkOrdersUpdateStavObjednavkyRequest(
        ordersIds, ordersStateId, sessionId
      )),
    [dispatch]
  );

  const createCollectionListItemFromOrder = useCallback(
    (orderId, collectionListId) =>
      dispatch(actions.ordersCreateCLItemFromOrderRequest(
        orderId, collectionListId
      )),
    [dispatch]
  );

  const fetchQueuedCollectionLists = useCallback(
    () =>
      dispatch(actions.ordersCollectionListQueuedRequest()),
    [dispatch]
  );

  const balikobotOrderEntryReset = useCallback(
    () =>
      dispatch(actions.ordersBalikobotOrderEntryReset()),
    [dispatch]
  );

  const createBalikobotOrderEntry = useCallback(
    (orderId, ident) =>
      dispatch(actions.ordersCreateBalikobotOrderEntryRequest(
        orderId, ident
      )),
    [dispatch]
  );

  const fetchBalikobotOrderEntry = useCallback(
    (orderId, isOrderSubscription=false) =>
      dispatch(actions.ordersBalikobotOrderEntryRequest(
        orderId, isOrderSubscription
      )),
    [dispatch]
  );

  const fetchMultipleBalikobotOrderEntry = useCallback(
    (ordersIds) =>
      dispatch(actions.ordersMultipleBalikobotOrderEntryRequest(
        ordersIds
      )),
    [dispatch]
  );

  const updateBalikobotOrderEntry = useCallback(
    (orderId, balikobotOrderEntry, isOrderSubscription=false) =>
      dispatch(actions.ordersUpdateBalikobotOrderEntryRequest(
        orderId, balikobotOrderEntry, isOrderSubscription
      )),
    [dispatch]
  );

  const generateBalikobotTag = useCallback(
    (orderId, balikobotOrderId, ident) =>
      dispatch(actions.ordersGenerateBalikobotTagRequest(
        orderId, balikobotOrderId, ident
      )),
    [dispatch]
  );

  const dropOrderFromBalikobot = useCallback(
    (orderId, ident) =>
      dispatch(actions.ordersDropOrderFromBalikobotRequest(
        orderId, ident
      )),
    [dispatch]
  );

  const createCollectionListItemsFromMultipleOrders = useCallback(
    (ordersIds, collectionListId) =>
      dispatch(actions.ordersCreateCLItemsFromMultipleOrderRequest(
        ordersIds, collectionListId
      )),
    [dispatch]
  );

  const fetchOrderDetailStatusLogs = useCallback(
    (orderId) =>
      dispatch(actions.ordersOrderDetailStatusLogsRequest(
        orderId
      )),
    [dispatch]
  );

  const fetchBalikobotOrderBranch = useCallback(
    (orderId, branchId) =>
      dispatch(actions.ordersBalikobotOrderBranchRequest(
        orderId, branchId
      )),
    [dispatch]
  );

  const resetBalikobotOrderBranch = useCallback(
    () =>
      dispatch(actions.ordersBalikobotOrderBranchReset()),
    [dispatch]
  );

  const fetchBranchesByShipperId = useCallback(
    (shipperId, search='') =>
      dispatch(actions.ordersBranchesByShipperIdRequest(shipperId, search)),
    [dispatch]
  );

  const resetBranchesByShipperId = useCallback(
    () =>
      dispatch(actions.ordersBranchesByShipperIdReset()),
    [dispatch]
  );

  const fetchTransportationTypesValidDraft = useCallback(
    () =>
      dispatch(actions.ordersTransportationTypesValidDraftRequest()),
    [dispatch]
  );

  const fetchServiceTypesByShipperId = useCallback(
    (shipperId) =>
      dispatch(actions.ordersServiceTypesByShipperIdRequest(shipperId)),
    [dispatch]
  );

  const resetServiceTypesByShipperId = useCallback(
    () =>
      dispatch(actions.ordersServiceTypesByShipperIdReset()),
    [dispatch]
  );

  const fetchOrdersCountByEmail = useCallback(
    (email, shop='ALL') =>
      dispatch(actions.ordersOrdersCountByEmailRequest(email, shop)),
    [dispatch]
  );

  const fetchAbraCommandsByOrderId = useCallback(
    (orderId) =>
      dispatch(actions.ordersOrderAbraCommandRequest(orderId)),
    [dispatch]
  );

  const createOrderSubscription = useCallback(
    (orderId, index=0) =>
      dispatch(actions.ordersCreateOrderSubscriptionRequest(
        orderId, index
      )),
    [dispatch]
  );

  // It creates orderSubscription and updates it with passed props right away
  // Only for suborders - must pass an index 1 - 6
  const createOrderSubscriptionWithUpdate = useCallback(
    (orderId, index, objectToUpdateWith) =>
      dispatch(actions.ordersCreateOrderSubscriptionWithUpdateRequest(
        orderId, index, objectToUpdateWith
      )),
    [dispatch]
  );

  const fetchSubscriptionsList = useCallback(
    () =>
      dispatch(actions.ordersSubscriptionsListRequest()),
    [dispatch]
  );

  const fetchOrderSubscriptionsList = useCallback(
    (orderId) =>
      dispatch(actions.ordersOrderSubscriptionsListRequest(orderId)),
    [dispatch]
  );

  const resetOrderSubscriptionsList = useCallback(
    () =>
      dispatch(actions.ordersOrderSubscriptionsListReset()),
    [dispatch]
  );

  const fetchOrdersStatesOrdersCounts = useCallback(
    () =>
      dispatch(actions.ordersOrdersCountsRequest()),
    [dispatch]
  );

  const fetchSourceSubscriptionsByIndex = useCallback(
    (index, page=0, limit=500) =>
      dispatch(actions.ordersSourceSubscriptionsByIndexRequest(
        index, page, limit
      )),
    [dispatch]
  );

  // orderObjs: { { [orderId]: orderId, objectToUpdateWith} }
  const createMultipleOrderSubscriptionWithUpdate = useCallback(
    (orderObjs, index,) =>
      dispatch(actions.ordersMultipleCreateOrderSubscriptionWithUpdateRequest(orderObjs, index,)),
    [dispatch]
  );

  // sourceOrderIdForRefetch - if supplied, the subs are refetched from the source order
  const deleteOrderSubscription = useCallback(
    (orderSubscriptionId, sourceOrderIdForRefetch=null) =>
      dispatch(actions.ordersDeleteOrderSubscriptionRequest(orderSubscriptionId, sourceOrderIdForRefetch)),
    [dispatch]
  );

  // sourceOrderIdForRefetch - if supplied, the subs are refetched from the source order
  const editOrderSubscription = useCallback(
    (updatedOrderSubscription, sourceOrderIdForRefetch=null) =>
      dispatch(actions.ordersEditOrderSubscriptionRequest(updatedOrderSubscription, sourceOrderIdForRefetch)),
    [dispatch]
  );

  // fetchSelectedOrderAddresses already exists by firmId
  // new version made for subscriptions
  const fetchSelectedOrderAddresses_NEW_VERSION = useCallback(
    (orderId) =>
      dispatch(actions.ordersSelectedOrderAddressesRequest(orderId)),
    [dispatch]
  );

  const setSelectedOrderAddress = useCallback(
    (orderId, index) =>
      dispatch(actions.ordersSetSelectedOrderAddressRequest(orderId, index)),
    [dispatch]
  );

  return {
    ordersTotal,
    ordersPageData,
    ordersPageDraw,
    ordersCurrentPage,

    orderDetailProductsPageData,
    orderDetailProductsPageDraw,
    orderDetailProductsPagePage,
    orderDetailProductsTotal,
    orderDetailProductsRelatedStoreCards,
    ordersResMessage,

    selectedOrder,
    selectedOrderFirm,
    selectedOrderPartnerMessages,
    selectedOrderAddresses,
    selectedOrderDeliveryList,
    selectedOrderBalikobotStatusLogs,
    selectedOrderAbraCommands,
    selectedOrderAbraCommandsErrors,
    selectedOrderSubscriptions,

    transportationTypes,
    transportationTypesDraftValid,
    stavObjednavky,
    ordersProductsPdfList,
    ordersFirmsPdfList,
    ordersStoreCardsPdfList,
    orderPartners,
    selectedOrderSyncStatuses,
    ordersGeneratedPdfDocument,
    redOrderStateFoldersCounts,
    queuedCollectionLists,
    balikobotOrderEntry,
    balikobotOrderBranch,
    branchesByShipperId,
    serviceTypesByShipperId,
    ordersCountByEmail,
    subscriptionsList,
    sourceSubscriptionsByIndex,
    selectedOrderAddresses_NEW_VERSION,

    fetchOrdersPageData,
    fetchTransportationTypes,
    fetchTransportationTypesValidDraft,
    fetchStavObjednavky,
    fetchOrderDetailProductsPageData,
    fetchSelectedOrderData,
    fetchSelectedOrderFirm,
    fetchOrderDeliveryList,
    fetchSelectedOrderAddresses,
    createNewPartnerMessage,
    createOrderItem,
    printOrdersDocument,
    nullifySelectedOrderFirm,
    updateOrderOrdersState,
    fetchOrdersProductsListForPdfList,
    nullifyOrdersProductsListForPdfList,
    sendOrderToStavEmail,
    sendOrderToStavSms,
    ordersResetResponseMessage,
    fetchSelectedOrderPartnerMessages,
    updateOrderItemQuantity,
    fetchOrderPartner,
    orderPartnersCloseStream,
    deleteOrderProduct,
    syncOrderWithAbra,
    createOrderDeliveryList,
    recreateOrderDeliveryList,
    checkOrderSyncStatuses,
    syncWithAbraWhileInAbra,
    printOrderBalikobot,
    printOrderThermal80,
    massPrintOrdersDocuments,
    generateOrdersTypePdfDocument,
    resetOrdersGeneratedPdf,
    massGenerateOrdersTypePdfDocument,
    updateBulkOrdersOrdersState,
    createCollectionListItemFromOrder,
    fetchQueuedCollectionLists,
    balikobotOrderEntryReset,
    createBalikobotOrderEntry,
    fetchBalikobotOrderEntry,
    updateBalikobotOrderEntry,
    generateBalikobotTag,
    dropOrderFromBalikobot,
    createCollectionListItemsFromMultipleOrders,
    fetchOrderDetailStatusLogs,
    fetchBalikobotOrderBranch,
    resetBalikobotOrderBranch,
    fetchBranchesByShipperId,
    resetBranchesByShipperId,
    fetchServiceTypesByShipperId,
    resetServiceTypesByShipperId,
    fetchOrdersCountByEmail,
    fetchAbraCommandsByOrderId,
    createOrderSubscription,
    fetchSubscriptionsList,
    fetchOrderSubscriptionsList,
    resetOrderSubscriptionsList,
    createOrderSubscriptionWithUpdate,
    fetchMultipleBalikobotOrderEntry,
    fetchOrdersStatesOrdersCounts,
    fetchSourceSubscriptionsByIndex,
    createMultipleOrderSubscriptionWithUpdate,
    deleteOrderSubscription,
    fetchSelectedOrderAddresses_NEW_VERSION,
    setSelectedOrderAddress,
    editOrderSubscription,
  }

}
