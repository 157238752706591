import * as actionTypes from './orders.actionTypes';

// Fetching a table page of orders
export function ordersListPageRequest(
  draw,
  page,
  orderStateId,
  transportationTypeId,
  type,
  search,
) {
  return {
    type: actionTypes.ORDERS_LIST_REQUEST,
    payload: { draw, page, orderStateId, transportationTypeId, type, search }
  };
}

export function ordersListPageResponse(response) {
  return {
    type: actionTypes.ORDERS_LIST_RESPONSE,
    payload: response
  };
}

export function ordersListPageFailure(error) {
  return {
    type: actionTypes.ORDERS_LIST_FAILURE,
    payload: error
  };
}

// Fetching valid transportation types
export function ordersTransportationTypesRequest() {
  return {
    type: actionTypes.ORDERS_TRANSPORTATION_TYPES_REQUEST,
  };
}

export function ordersTransportationTypesResponse(response) {
  return {
    type: actionTypes.ORDERS_TRANSPORTATION_TYPES_RESPONSE,
    payload: response
  };
}

export function ordersTransportationTypesFailure(error) {
  return {
    type: actionTypes.ORDERS_TRANSPORTATION_TYPES_FAILURE,
    payload: error
  };
}

// Fetching stavy objednavky
export function ordersStavObjednavkyRequest() {
  return {
    type: actionTypes.ORDERS_STAV_OBJEDNAVKY_REQUEST,
  };
}

// Fetching order detail products
export function ordersOrderDetailProductsPageRequest(orderId, draw, page) {
  return {
    type: actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_REQUEST,
    payload: { draw, page, orderId }
  };
}

// Fetching the selected order
export function ordersSelectedOrderRequest(orderId) {
  return {
    type: actionTypes.ORDERS_SELECTED_ORDER_REQUEST,
    payload: orderId
  };
}

export function ordersSelectedOrderResponse(response) {
  return {
    type: actionTypes.ORDERS_SELECTED_ORDER_RESPONSE,
    payload: response
  };
}

export function ordersSelectedOrderFailure(error) {
  return {
    type: actionTypes.ORDERS_SELECTED_ORDER_FAILURE,
    payload: error
  };
}

// Update stav objednavky (order orders state)
export function ordersUpdateStavObjednavkyRequest(
  orderId, ordersStateId, sessionId
) {
  return {
    type: actionTypes.ORDERS_UPDATE_STAV_OBJEDNAVKY_REQUEST,
    payload: { orderId, ordersStateId, sessionId }
  };
}

export function ordersUpdateStavObjednavkyResponse(response) {
  return {
    type: actionTypes.ORDERS_UPDATE_STAV_OBJEDNAVKY_RESPONSE,
    payload: response
  };
}

export function ordersUpdateStavObjednavkyFailure(error) {
  return {
    type: actionTypes.ORDERS_UPDATE_STAV_OBJEDNAVKY_FAILURE,
    payload: error
  };
}

// Fetch firm
export function ordersFirmRequest(abraId) {
  return {
    type: actionTypes.ORDERS_FIRM_REQUEST,
    payload: abraId
  };
}

export function ordersFirmReset() {
  return {
    type: actionTypes.ORDERS_FIRM_RESET,
  };
}

// Fetching multiples orders' detail products for pdf list
export function ordersOrderProductsForPdfListRequest(ordersIds, ordersFirmIds) {
  return {
    type: actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_FOR_PDF_REQUEST,
    payload: { ordersIds, ordersFirmIds }
  };
}

export function ordersOrderProductsForPdfListReset() {
  return {
    type: actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_FOR_PDF_RESET
  };
}

// After setting order as Připravena, send sms
export function ordersOrderToStavSendSmsRequest(orderId, stavObjednavkyId) {
  return {
    type: actionTypes.ORDERS_ORDER_TO_STAV_SEND_SMS_REQUEST,
    payload: { orderId, stavObjednavkyId }
  };
}

// After setting order as Připravena, send email
export function ordersOrderToStavSendEmailRequest(orderId, stavObjednavkyId) {
  return {
    type: actionTypes.ORDERS_ORDER_TO_STAV_SEND_EMAIL_REQUEST,
    payload: { orderId, stavObjednavkyId }
  };
}

// Reset response message
export function ordersResponseMessageReset() {
  return {
    type: actionTypes.ORDERS_RESPONSE_MESSAGE_RESET
  };
}

// Fetch partner messages
export function ordersPartnerMessagesRequest(orderId) {
  return {
    type: actionTypes.ORDERS_PARTNER_MESSAGES_REQUEST,
    payload: orderId
  };
}

// Change selected order item's quantity
export function ordersUpdateOrderItemQuantityRequest(orderItemId, quantity, ident) {
  return {
    type: actionTypes.ORDERS_ORDER_ITEM_UPDATE_QUANTITY_REQUEST,
    payload: { orderItemId, quantity, ident }
  };
}

// Fetch selected order addresses
export function ordersOrderAddressesRequest(firmAbraId, firmOfficeAbraId) {
  return {
    type: actionTypes.ORDERS_ADDRESS_REQUEST,
    payload: { firmAbraId, firmOfficeAbraId }
  };
}

export function ordersOrderAddressesResponse(response) {
  return {
    type: actionTypes.ORDERS_ADDRESS_RESPONSE,
    payload: response
  };
}

export function ordersOrderAddressesFailure(error) {
  return {
    type: actionTypes.ORDERS_ADDRESS_FAILURE,
    payload: error
  };
}

// Fetch partner for messages
export function ordersPartnerRequest(partnerId) {
  return {
    type: actionTypes.ORDERS_PARTNER_REQUEST,
    payload: partnerId
  };
}

export function ordersPartnerCloseStream() {
  return {
    type: actionTypes.ORDERS_PARTNER_CLOSE_STREAM,
  };
}

// Delete selected order item
export function ordersOrderItemDeleteRequest(productId, ident) {
  return {
    type: actionTypes.ORDERS_ORDER_ITEM_DELETE_REQUEST,
    payload: { productId, ident }
  };
}

// Create new message
export function ordersNewPartnerMessageRequest(orderId, authorPartnerId, message) {
  return {
    type: actionTypes.ORDERS_NEW_PARTNER_MESSAGE_REQUEST,
    payload: { orderId, authorPartnerId, message }
  };
}

// Sync order with ABRA
export function ordersOrderSyncAbraRequest(orderExternalNumber) {
  return {
    type: actionTypes.ORDERS_ORDER_SYNC_ABRA_REQUEST,
    payload: orderExternalNumber
  };
}

// Create delivery list from order
export function ordersDeliveryListCreateRequest(orderId, ident) {
  return {
    type: actionTypes.ORDERS_CREATE_DELIVERY_LIST_REQUEST,
    payload: { orderId, ident }
  };
}

// Rereate delivery list from order
export function ordersDeliveryListRecreateRequest(deliveryListId) {
  return {
    type: actionTypes.ORDERS_RECREATE_DELIVERY_LIST_REQUEST,
    payload: deliveryListId
  };
}

// Fetch delivery list of an order
export function ordersOrderDeliveryListRequest(orderId) {
  return {
    type: actionTypes.ORDERS_ORDER_DELIVERY_LIST_REQUEST,
    payload: orderId
  };
}

// Add item to order
export function ordersAddOrderItemRequest(
  sessionId, userUid, orderId, storeCardId, quantity, rowtype
) {
  return {
    type: actionTypes.ORDERS_ORDER_CREATE_ITEM_REQUEST,
    payload: {
      params: {
        ident: sessionId,
        partnersUid: userUid,
      },
      body: {
        orderId,
        storecardId: storeCardId,
        quantity,
        rowtype,
      }
    }
  };
}

export function ordersAddOrderItemResponse(response, resMessage) {
  return {
    type: actionTypes.ORDERS_ORDER_CREATE_ITEM_RESPONSE,
    payload: { response, resMessage }
  };
}

export function ordersAddOrderItemFailure(error, resMessage) {
  return {
    type: actionTypes.ORDERS_ORDER_CREATE_ITEM_FAILURE,
    payload: { error, resMessage }
  };
}

// Sync statuses check
export function ordersOrderSyncStatusesCheckRequest(externalNumber) {
  return {
    type: actionTypes.ORDERS_ORDER_SYNC_STATUSES_CHECK_REQUEST,
    payload: { externalNumber }
  };
}

export function ordersOrderSyncStatusesCheckResponse(response) {
  return {
    type: actionTypes.ORDERS_ORDER_SYNC_STATUSES_CHECK_RESPONSE,
    payload: response
  };
}

export function ordersOrderSyncStatusesCheckFailure(error) {
  return {
    type: actionTypes.ORDERS_ORDER_SYNC_STATUSES_CHECK_FAILURE,
    payload: error
  };
}

// Sync with abra while already in abra
export function ordersOrderSyncWithAbraWhileInAbraRequest(externalNumber) {
  return {
    type: actionTypes.ORDERS_SYNC_ABRA_WHILE_IN_ABRA_REQUEST,
    payload: externalNumber
  };
}

export function ordersOrderSyncWithAbraWhileInAbraResponse(response, resMessage) {
  return {
    type: actionTypes.ORDERS_SYNC_ABRA_WHILE_IN_ABRA_RESPONSE,
    payload: { response, resMessage }
  };
}

export function ordersOrderSyncWithAbraWhileInAbraFailure(error, resMessage) {
  return {
    type: actionTypes.ORDERS_SYNC_ABRA_WHILE_IN_ABRA_FAILURE,
    payload: { error, resMessage }
  };
}

// Print request
export function ordersPrintDocumentRequest(type, itemId, userUid, ident) {
  return {
    type: actionTypes.ORDERS_PRINT_DOCUMENT_REQUEST,
    payload: { type, itemId, userUid, ident }
  };
}

export function ordersPrintDocumentResponse(response, resMessage) {
  return {
    type: actionTypes.ORDERS_PRINT_DOCUMENT_RESPONSE,
    payload: { response, resMessage }
  };
}

export function ordersPrintDocumentFailure(error, resMessage) {
  return {
    type: actionTypes.ORDERS_PRINT_DOCUMENT_FAILURE,
    payload: { error, resMessage }
  };
}

// Print balikobot request
export function ordersPrintBalikobotRequest(orderId, userUid, ident) {
  return {
    type: actionTypes.ORDERS_PRINT_BALIKOBOT_REQUEST,
    payload: { orderId, userUid, ident }
  };
}

// Print balikobot request
export function ordersPrintThermal80Request(type, orderId, userUid, ident) {
  return {
    type: actionTypes.ORDERS_PRINT_THERMAL80_REQUEST,
    payload: { type, orderId, userUid, ident }
  };
}

export function ordersPrintBalikobotResponse(response, resMessage) {
  return {
    type: actionTypes.ORDERS_PRINT_BALIKOBOT_RESPONSE,
    payload: { response, resMessage }
  };
}

export function ordersPrintBalikobotFailure(error, resMessage) {
  return {
    type: actionTypes.ORDERS_PRINT_BALIKOBOT_FAILURE,
    payload: { error, resMessage }
  };
}

// Mass print request
export function ordersMassPrintDocumentsRequest(type, itemIds, userUid, ident) {
  return {
    type: actionTypes.ORDERS_MASS_PRINT_DOCUMENTS_REQUEST,
    payload: { type, itemIds, userUid, ident }
  };
}

export function ordersMassPrintDocumentsResponse(response, resMessage) {
  return {
    type: actionTypes.ORDERS_MASS_PRINT_DOCUMENTS_RESPONSE,
    payload: { response, resMessage }
  };
}

export function ordersMassPrintDocumentsFailure(error, resMessage) {
  return {
    type: actionTypes.ORDERS_MASS_PRINT_DOCUMENTS_FAILURE,
    payload: { error, resMessage }
  };
}

// Generate PDF request
export function ordersGeneratePdfDocumentRequest(type, itemId, userUid, ident) {
  return {
    type: actionTypes.ORDERS_GENERATE_PDF_REQUEST,
    payload: { type, itemId, userUid, ident }
  };
}

export function ordersGeneratePdfDocumentResponse(response, resMessage) {
  return {
    type: actionTypes.ORDERS_GENERATE_PDF_RESPONSE,
    payload: { response, resMessage }
  };
}

export function ordersGeneratePdfDocumentFailure(error, resMessage) {
  return {
    type: actionTypes.ORDERS_GENERATE_PDF_FAILURE,
    payload: { error, resMessage }
  };
}

// Reset generated PDF
export function ordersResetGeneratedPdf() {
  return {
    type: actionTypes.ORDERS_RESET_GENERATED_PDF,
  };
}

// Mass generate PDF request
export function ordersMassGeneratePdfDocumentRequest(type, itemIds, userUid, ident) {
  return {
    type: actionTypes.ORDERS_MASS_GENERATE_PDF_REQUEST,
    payload: { type, itemIds, userUid, ident }
  };
}

export function ordersMassGeneratePdfDocumentResponse(response, resMessage) {
  return {
    type: actionTypes.ORDERS_MASS_GENERATE_PDF_RESPONSE,
    payload: { response, resMessage }
  };
}

export function ordersMassGeneratePdfDocumentFailure(error, resMessage) {
  return {
    type: actionTypes.ORDERS_MASS_GENERATE_PDF_FAILURE,
    payload: { error, resMessage }
  };
}

// Update bulk orders stav objednavky
export function ordersBulkOrdersUpdateStavObjednavkyRequest(
  ordersIds, ordersStateId, sessionId
) {
  return {
    type: actionTypes.ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_REQUEST,
    payload: { ordersIds, ordersStateId, sessionId }
  };
}

export function ordersBulkOrdersUpdateStavObjednavkyResponse(
  response, resMessage
) {
  return {
    type: actionTypes.ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_RESPONSE,
    payload: { response, resMessage }
  };
}

export function ordersBulkOrdersUpdateStavObjednavkyFailure(
  error, resMessage
) {
  return {
    type: actionTypes.ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_FAILURE,
    payload: { error, resMessage }
  };
}

// Create collection list item from order
export function ordersCreateCLItemFromOrderRequest(
  orderId, collectionListId
) {
  return {
    type: actionTypes.ORDERS_CREATE_CLITEM_FROM_ORDER_REQUEST,
    payload: { orderId, collectionListId }
  };
}

export function ordersCreateCLItemFromOrderResponse(
  response, resMessage
) {
  return {
    type: actionTypes.ORDERS_CREATE_CLITEM_FROM_ORDER_RESPONSE,
    payload: { response, resMessage }
  };
}

export function ordersCreateCLItemFromOrderFailure(
  error, resMessage
) {
  return {
    type: actionTypes.ORDERS_CREATE_CLITEM_FROM_ORDER_FAILURE,
    payload: { error, resMessage }
  };
}

// Fetch collection list with QUEUED status only
export function ordersCollectionListQueuedRequest() {
  return {
    type: actionTypes.ORDERS_COLLECTION_LIST_QUEUED_REQUEST,
  };
}

export function ordersCollectionListQueuedResponse(
  response
) {
  return {
    type: actionTypes.ORDERS_COLLECTION_LIST_QUEUED_RESPONSE,
    payload: response
  };
}

export function ordersCollectionListQueuedFailure(
  error
) {
  return {
    type: actionTypes.ORDERS_COLLECTION_LIST_QUEUED_FAILURE,
    payload: error
  };
}

// Create balikobot order entry
export function ordersCreateBalikobotOrderEntryRequest(
  orderId, ident
) {
  return {
    type: actionTypes.ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_REQUEST,
    payload: { orderId, ident }
  };
}

export function ordersCreateBalikobotOrderEntryResponse(
  response, resMessage
) {
  return {
    type: actionTypes.ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_RESPONSE,
    payload: { response, resMessage }
  };
}

export function ordersCreateBalikobotOrderEntryFailure(
  error, resMessage
) {
  return {
    type: actionTypes.ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_FAILURE,
    payload: { error, resMessage }
  };
}

// Reset balikobot order entry
export function ordersBalikobotOrderEntryReset() {
  return {
    type: actionTypes.ORDERS_BALIKOBOT_ORDER_ENTRY_RESET,
  };
}

// Fetch balikobot order entry
export function ordersBalikobotOrderEntryRequest(orderId, isOrderSubscription) {
  return {
    type: actionTypes.ORDERS_BALIKOBOT_ORDER_ENTRY_REQUEST,
    payload: { orderId, isOrderSubscription }
  };
}

export function ordersBalikobotOrderEntryResponse(response) {
  return {
    type: actionTypes.ORDERS_BALIKOBOT_ORDER_ENTRY_RESPONSE,
    payload: response
  };
}

export function ordersBalikobotOrderEntryFailure(error) {
  return {
    type: actionTypes.ORDERS_BALIKOBOT_ORDER_ENTRY_FAILURE,
    payload: error
  };
}

// Multiple fetch balikobot order entry
export function ordersMultipleBalikobotOrderEntryRequest(ordersIds) {
  return {
    type: actionTypes.ORDERS_MULTIPLE_BALIKOBOT_ORDER_ENTRY_REQUEST,
    payload: { ordersIds }
  };
}

export function ordersMultipleBalikobotOrderEntryResponse(response) {
  return {
    type: actionTypes.ORDERS_MULTIPLE_BALIKOBOT_ORDER_ENTRY_RESPONSE,
    payload: response
  };
}

export function ordersMultipleBalikobotOrderEntryFailure(error) {
  return {
    type: actionTypes.ORDERS_MULTIPLE_BALIKOBOT_ORDER_ENTRY_FAILURE,
    payload: error
  };
}

// Update balikobot order entry
export function ordersUpdateBalikobotOrderEntryRequest(orderId, balikobotOrderEntry, isOrderSubscription) {
  return {
    type: actionTypes.ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_REQUEST,
    payload: { orderId, balikobotOrderEntry, isOrderSubscription }
  };
}

export function ordersUpdateBalikobotOrderEntryResponse(
  response, resMessage
) {
  return {
    type: actionTypes.ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_RESPONSE,
    payload: { response, resMessage }
  };
}

export function ordersUpdateBalikobotOrderEntryFailure(
  error, resMessage
) {
  return {
    type: actionTypes.ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_FAILURE,
    payload: { error, resMessage }
  };
}

// Generate balikobot tag from balikobot order entry
export function ordersGenerateBalikobotTagRequest(
  orderId, balikobotOrderId, ident
) {
  return {
    type: actionTypes.ORDERS_GENERATE_BALIKOBOT_TAG_REQUEST,
    payload: { orderId, balikobotOrderId, ident }
  };
}

export function ordersGenerateBalikobotTagResponse(
  response, resMessage
) {
  return {
    type: actionTypes.ORDERS_GENERATE_BALIKOBOT_TAG_RESPONSE,
    payload: { response, resMessage }
  };
}

export function ordersGenerateBalikobotTagFailure(
  error, resMessage
) {
  return {
    type: actionTypes.ORDERS_GENERATE_BALIKOBOT_TAG_FAILURE,
    payload: { error, resMessage }
  };
}

// Drop order from balikobot
export function ordersDropOrderFromBalikobotRequest(
  orderId, ident
) {
  return {
    type: actionTypes.ORDERS_DROP_ORDER_FROM_BALIKOBOT_REQUEST,
    payload: { orderId, ident }
  };
}

export function ordersDropOrderFromBalikobotResponse(
  response, resMessage
) {
  return {
    type: actionTypes.ORDERS_DROP_ORDER_FROM_BALIKOBOT_RESPONSE,
    payload: { response, resMessage }
  };
}

export function ordersDropOrderFromBalikobotFailure(
  error, resMessage
) {
  return {
    type: actionTypes.ORDERS_DROP_ORDER_FROM_BALIKOBOT_FAILURE,
    payload: { error, resMessage }
  };
}

// Create collection list items from multiple orders
export function ordersCreateCLItemsFromMultipleOrderRequest(
  ordersIds, collectionListId
) {
  return {
    type: actionTypes.ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_REQUEST,
    payload: { ordersIds, collectionListId }
  };
}

export function ordersCreateCLItemsFromMultipleOrderResponse(
  response, resMessage
) {
  return {
    type: actionTypes.ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_RESPONSE,
    payload: { response, resMessage }
  };
}

export function ordersCreateCLItemsFromMultipleOrderFailure(
  error, resMessage
) {
  return {
    type: actionTypes.ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_FAILURE,
    payload: { error, resMessage }
  };
}

// Fetch order detail status logs
export function ordersOrderDetailStatusLogsRequest(
  orderId
) {
  return {
    type: actionTypes.ORDERS_ORDER_DETAIL_STATUS_LOGS_REQUEST,
    payload: { orderId }
  };
}

export function ordersOrderDetailStatusLogsResponse(
  response
) {
  return {
    type: actionTypes.ORDERS_ORDER_DETAIL_STATUS_LOGS_RESPONSE,
    payload: response
  };
}

export function ordersOrderDetailStatusLogsFailure(
  error
) {
  return {
    type: actionTypes.ORDERS_ORDER_DETAIL_STATUS_LOGS_FAILURE,
    payload: error
  };
}

// Fetch balikobot order branch
export function ordersBalikobotOrderBranchRequest(orderId, branchId) {
  return {
    type: actionTypes.ORDERS_BALIKOBOT_ORDER_BRANCH_REQUEST,
    payload: { orderId, branchId }
  };
}

export function ordersBalikobotOrderBranchResponse(response) {
  return {
    type: actionTypes.ORDERS_BALIKOBOT_ORDER_BRANCH_RESPONSE,
    payload: response
  };
}

export function ordersBalikobotOrderBranchFailure(error) {
  return {
    type: actionTypes.ORDERS_BALIKOBOT_ORDER_BRANCH_FAILURE,
    payload: error
  };
}

export function ordersBalikobotOrderBranchReset() {
  return {
    type: actionTypes.ORDERS_BALIKOBOT_ORDER_BRANCH_RESET,
  };
}

// Fetch branches by shipper id
export function ordersBranchesByShipperIdRequest(shipperId, search) {
  return {
    type: actionTypes.ORDERS_BRANCHES_BY_SHIPPER_ID_REQUEST,
    payload: { shipperId, search }
  };
}

export function ordersBranchesByShipperIdResponse(response) {
  return {
    type: actionTypes.ORDERS_BRANCHES_BY_SHIPPER_ID_RESPONSE,
    payload: response
  };
}

export function ordersBranchesByShipperIdFailure(error) {
  return {
    type: actionTypes.ORDERS_BRANCHES_BY_SHIPPER_ID_FAILURE,
    payload: error
  };
}

export function ordersBranchesByShipperIdReset() {
  return {
    type: actionTypes.ORDERS_BRANCHES_BY_SHIPPER_ID_RESET,
  };
}

// Fetching valid transportation types draft valid
export function ordersTransportationTypesValidDraftRequest() {
  return {
    type: actionTypes.ORDERS_TRANSPORTATION_TYPES_DRAFT_VALID_REQUEST,
  };
}

export function ordersTransportationTypesValidDraftResponse(response) {
  return {
    type: actionTypes.ORDERS_TRANSPORTATION_TYPES_DRAFT_VALID_RESPONSE,
    payload: response
  };
}

export function ordersTransportationTypesValidDraftFailure(error) {
  return {
    type: actionTypes.ORDERS_TRANSPORTATION_TYPES_DRAFT_VALID_FAILURE,
    payload: error
  };
}

// Fetch branches by shipper id
export function ordersServiceTypesByShipperIdRequest(shipperId) {
  return {
    type: actionTypes.ORDERS_SERVICE_TYPES_BY_SHIPPER_ID_REQUEST,
    payload: { shipperId }
  };
}

export function ordersServiceTypesByShipperIdResponse(response) {
  return {
    type: actionTypes.ORDERS_SERVICE_TYPES_BY_SHIPPER_ID_RESPONSE,
    payload: response
  };
}

export function ordersServiceTypesByShipperIdFailure(error) {
  return {
    type: actionTypes.ORDERS_SERVICE_TYPES_BY_SHIPPER_ID_FAILURE,
    payload: error
  };
}

export function ordersServiceTypesByShipperIdReset() {
  return {
    type: actionTypes.ORDERS_SERVICE_TYPES_BY_SHIPPER_ID_RESET,
  };
}

// Orders count by email
export function ordersOrdersCountByEmailRequest(email, shop) {
  return {
    type: actionTypes.ORDERS_ORDERS_COUNT_BY_EMAIL_REQUEST,
    payload: { email, shop }
  };
}

export function ordersOrdersCountByEmailResponse(response) {
  return {
    type: actionTypes.ORDERS_ORDERS_COUNT_BY_EMAIL_RESPONSE,
    payload: response
  };
}

export function ordersOrdersCountByEmailFailure(error) {
  return {
    type: actionTypes.ORDERS_ORDERS_COUNT_BY_EMAIL_FAILURE,
    payload: error
  };
}

// Order obra command
export function ordersOrderAbraCommandRequest(orderId) {
  return {
    type: actionTypes.ORDERS_ORDER_ABRA_COMMAND_REQUEST,
    payload: { orderId }
  };
}

export function ordersOrderAbraCommandResponse(response) {
  return {
    type: actionTypes.ORDERS_ORDER_ABRA_COMMAND_RESPONSE,
    payload: response
  };
}

export function ordersOrderAbraCommandFailure(error) {
  return {
    type: actionTypes.ORDERS_ORDER_ABRA_COMMAND_FAILURE,
    payload: error
  };
}

// Create order subscription
export function ordersCreateOrderSubscriptionRequest(
  orderId, index,
) {
  return {
    type: actionTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_REQUEST,
    payload: { orderId, index }
  };
}

export function ordersCreateOrderSubscriptionResponse(response, resMessage) {
  return {
    type: actionTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_RESPONSE,
    payload: { response, resMessage }
  };
}

export function ordersCreateOrderSubscriptionFailure(error, resMessage) {
  return {
    type: actionTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_FAILURE,
    payload: { error, resMessage }
  };
}

export function ordersCreateOrderSubscriptionWithUpdateRequest(
  orderId, index, objectToUpdateWith
) {
  return {
    type: actionTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_WITH_UPDATE_REQUEST,
    payload: { orderId, index, objectToUpdateWith }
  };
}

// Subscriptions list
export function ordersSubscriptionsListRequest() {
  return {
    type: actionTypes.ORDERS_SUBSCRIPTIONS_LIST_REQUEST,
  };
}

export function ordersSubscriptionsListResponse(response) {
  return {
    type: actionTypes.ORDERS_SUBSCRIPTIONS_LIST_RESPONSE,
    payload: response,
  };
}

export function ordersSubscriptionsListFailure(error) {
  return {
    type: actionTypes.ORDERS_SUBSCRIPTIONS_LIST_FAILURE,
    payload: error,
  };
}

// Order orderSubscriptions list
export function ordersOrderSubscriptionsListRequest(orderId) {
  return {
    type: actionTypes.ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_REQUEST,
    payload: { orderId }
  };
}

export function ordersOrderSubscriptionsListResponse(response) {
  return {
    type: actionTypes.ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_RESPONSE,
    payload: response
  };
}

export function ordersOrderSubscriptionsListFailure(error) {
  return {
    type: actionTypes.ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_FAILURE,
    payload: error
  };
}

export function ordersOrderSubscriptionsListReset() {
  return {
    type: actionTypes.ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_RESET,
  };
}

// Orders counts
export function ordersOrdersCountsRequest() {
  return {
    type: actionTypes.ORDERS_ORDERS_COUNTS_REQUEST,
  };
}

export function ordersOrdersCountsResponse(response) {
  return {
    type: actionTypes.ORDERS_ORDERS_COUNTS_RESPONSE,
    payload: response,
  };
}

export function ordersOrdersCountsFailure(error) {
  return {
    type: actionTypes.ORDERS_ORDERS_COUNTS_FAILURE,
    payload: error,
  };
}

// Source subscriptions by index
export function ordersSourceSubscriptionsByIndexRequest(index, page, limit) {
  return {
    type: actionTypes.ORDERS_SOURCE_SUBSCRIPTIONS_BY_INDEX_REQUEST,
    payload: { index, page, limit },
  };
}

export function ordersSourceSubscriptionsByIndexResponse(response) {
  return {
    type: actionTypes.ORDERS_SOURCE_SUBSCRIPTIONS_BY_INDEX_RESPONSE,
    payload: response,
  };
}

export function ordersSourceSubscriptionsByIndexFailure(error) {
  return {
    type: actionTypes.ORDERS_SOURCE_SUBSCRIPTIONS_BY_INDEX_FAILURE,
    payload: error,
  };
}

// orderObjs: { { [orderId]: orderId, objectToUpdateWith} }
// Multiple create orders subscriptions
export function ordersMultipleCreateOrderSubscriptionWithUpdateRequest(
  orderObjs, index,
) {
  return {
    type: actionTypes.ORDERS_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_REQUEST,
    payload: { orderObjs, index, }
  };
}

export function ordersMultipleCreateOrderSubscriptionWithUpdateResponse(
  response, resMessage,
) {
  return {
    type: actionTypes.ORDERS_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_RESPONSE,
    payload: { response, resMessage, }
  };
}

export function ordersMultipleCreateOrderSubscriptionWithUpdateFailure(
  error, resMessage,
) {
  return {
    type: actionTypes.ORDERS_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_FAILURE,
    payload: { error, resMessage, }
  };
}

// Delete order subscription
export function ordersDeleteOrderSubscriptionRequest(orderSubscriptionId, sourceOrderIdForRefetch) {
  return {
    type: actionTypes.ORDERS_DELETE_ORDER_SUBSCRIPTION_REQUEST,
    payload: { orderSubscriptionId, sourceOrderIdForRefetch },
  };
}

export function ordersDeleteOrderSubscriptionResponse(response, resMessage) {
  return {
    type: actionTypes.ORDERS_DELETE_ORDER_SUBSCRIPTION_RESPONSE,
    payload: { response, resMessage },
  };
}

export function ordersDeleteOrderSubscriptionFailure(error, resMessage) {
  return {
    type: actionTypes.ORDERS_DELETE_ORDER_SUBSCRIPTION_FAILURE,
    payload: { error, resMessage },
  };
}

// Edit order subscription
export function ordersEditOrderSubscriptionRequest(
  updatedOrderSubscription, sourceOrderIdForRefetch
) {
  return {
    type: actionTypes.ORDERS_EDIT_ORDER_SUBSCRIPTION_REQUEST,
    payload: { updatedOrderSubscription, sourceOrderIdForRefetch },
  };
}

export function ordersEditOrderSubscriptionResponse(response, resMessage) {
  return {
    type: actionTypes.ORDERS_EDIT_ORDER_SUBSCRIPTION_RESPONSE,
    payload: { response, resMessage },
  };
}

export function ordersEditOrderSubscriptionFailure(error, resMessage) {
  return {
    type: actionTypes.ORDERS_EDIT_ORDER_SUBSCRIPTION_FAILURE,
    payload: { error, resMessage },
  };
}

// Get selected order addresses
export function ordersSelectedOrderAddressesRequest(orderId) {
  return {
    type: actionTypes.ORDERS_GET_SELECTED_ORDER_ADDRESSES_REQUEST,
    payload: { orderId },
  };
}

export function ordersSelectedOrderAddressesResponse(response) {
  return {
    type: actionTypes.ORDERS_GET_SELECTED_ORDER_ADDRESSES_RESPONSE,
    payload: response,
  };
}

export function ordersSelectedOrderAddressesFailure(error) {
  return {
    type: actionTypes.ORDERS_GET_SELECTED_ORDER_ADDRESSES_FAILURE,
    payload: error,
  };
}

// Set selected order address
export function ordersSetSelectedOrderAddressRequest(orderId, index) {
  return {
    type: actionTypes.ORDERS_SET_SELECTED_ORDER_ADDRESS_REQUEST,
    payload: { orderId, index },
  };
}

export function ordersSetSelectedOrderAddressResponse(response, resMessage) {
  return {
    type: actionTypes.ORDERS_SET_SELECTED_ORDER_ADDRESS_RESPONSE,
    payload: { response, resMessage },
  };
}

export function ordersSetSelectedOrderAddressFailure(error, resMessage) {
  return {
    type: actionTypes.ORDERS_SET_SELECTED_ORDER_ADDRESS_FAILURE,
    payload: { error, resMessage },
  };
}
