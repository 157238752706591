import * as actionTypes from './orders.actionTypes';
import * as actions from './orders.actions';
import { CraftApi, AirshopApi } from '../../CraftApi.axios';
import { from, of, forkJoin, concat } from 'rxjs';
import { mergeMap, switchMap, takeUntil, catchError, map, toArray, concatMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import _ from 'lodash';
import moment from 'moment';
import { notificationTypes } from 'libs/crud-notifications'
import { err, errMessages, passOnlySuccessForkJoinResponses, handleForkJoinResponses } from 'redux-store/utils/epics.utils'

export const fetchOrdersPageEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_LIST_REQUEST),
    mergeMap(action => {
      const {
        draw,
        page,
      } = action.payload;

      const optionalParams = {};
      Object.entries(action.payload).forEach(item => {
        const [key, value] = item;
        if (!(value === draw || value === page)) {
          // orderStateId is named differently in params - as stavObjednavkyId
          if (key === 'orderStateId') {
            optionalParams['stavObjednavkyId'] = value;
          } else {
            optionalParams[key] = value;
          }
        }
      });
      return from(AirshopApi.get('orders', {
        params: {
          draw,
          limit: 50,
          order: 'date_created',
          page: page * 50,
          sort: 'DESC',
          ...optionalParams,
          // Draft valid temporary
          // transportationTypeId: 1360000101,
        }
      }
    ))
      .pipe(
          map(response => response.data.status === 'OK' ? {
            items: response.data.items,
            total: response.data.total,
          } : err(errMessages.NOT_OK, response)),
          map(tablePageData => actions.ordersListPageResponse(tablePageData)),
          catchError(error => of(actions.ordersListPageFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_LIST_RESPONSE ||
              actionTypes.ORDERS_LIST_FAILURE
          ))
      )
    })
  );

export const fetchOrdersStatesOrdersCountsEpic = action$ =>
  action$.pipe(
    ofType(
      actionTypes.ORDERS_ORDERS_COUNTS_REQUEST,
      // Refetch on every orders response
      actionTypes.ORDERS_LIST_RESPONSE
    ),
    mergeMap(action => from(AirshopApi.get('stavObjednavky/allOrdersCount'))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(items => {
            const redOrderStateFoldersCounts = {}
            items?.length && items.forEach(item => {
              redOrderStateFoldersCounts[item.abraId] = item.orderCount;
            });
            return actions.ordersOrdersCountsResponse(redOrderStateFoldersCounts)
          }),
          catchError(error => of(actions.ordersOrdersCountsFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_ORDERS_COUNTS_RESPONSE ||
            actionTypes.ORDERS_ORDERS_COUNTS_FAILURE
          ))
      )
    )
  );

export const fetchTransportationTypesEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_TRANSPORTATION_TYPES_REQUEST),
    mergeMap(action => from(AirshopApi.get('transportationTypes/valid', {
      params: {
        limit: 100,
      }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(transTypes => actions.ordersTransportationTypesResponse(transTypes)),
          catchError(error => of(actions.ordersTransportationTypesFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_TRANSPORTATION_TYPES_RESPONSE ||
              actionTypes.ORDERS_TRANSPORTATION_TYPES_FAILURE
          ))
      )
    )
  );

export const fetchStavObjednavkyEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_STAV_OBJEDNAVKY_REQUEST),
    mergeMap(action => from(AirshopApi.get('stavObjednavky', {
      params: {
        limit: 100,
      }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(stavObjednavky => {
            return {
              type: actionTypes.ORDERS_STAV_OBJEDNAVKY_RESPONSE,
              payload: stavObjednavky,
            }
          }),
          catchError(error => of({
              type: actionTypes.ORDERS_STAV_OBJEDNAVKY_FAILURE,
              payload: error,
            })
          ),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_STAV_OBJEDNAVKY_RESPONSE ||
              actionTypes.ORDERS_STAV_OBJEDNAVKY_FAILURE
          ))
      )
    )
  );

export const fetchOrderDetailProductsPageEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_REQUEST),
    mergeMap((action) => {
      const { draw, orderId } = action.payload;
      return from(
        AirshopApi.get(`orderItems/ordersId/${orderId}`, {
          params: {
            draw,
            limit: 500, // logic from previous AngularJS app
            order: 'rowtype',
            sort: 'DESC',
            page: 0,
          },
        })
      ).pipe(
        map((response) =>
          response.data.status === 'OK'
            ? {
                products: response.data.items,
                total: response.data.total,
              }
            : err(errMessages.NOT_OK, response)
        ),
        $fetchRelatedStoreCards(),
        catchError((error) =>
          of({
            type: actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_FAILURE,
            payload: error,
          })
        ),
        takeUntil(
          action$.ofType(
            actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_RESPONSE ||
              actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_FAILURE
          )
        )
      );
    })
  );

// use with return in epic
const $getRelatedStoreCardsForkJoin = products => {
  const storeCardsIds = [];
  // logic from AngularJS ordersDetail.js -> rowCallback function
  products.forEach((product) => {
    if (product.rowtype === 3 && product.storecardId) {
      storeCardsIds.push(product.storecardId);
    }
  });
  return forkJoin(
    storeCardsIds.map((id) => AirshopApi.get(`storeCards/abraId/${id}`))
  )
}

const $fetchRelatedStoreCards = () => mergeMap((itemsWithTotal) => {
  const { products } = itemsWithTotal;
  return $getRelatedStoreCardsForkJoin(products)
  .pipe(
    map((response) => {
      return response ? response.map((item) => item.data.data) : null;
    }),
    map((response) => {
      const collectedResponses = {
        ...itemsWithTotal,
        storeCards: response,
      };
      return {
        type: actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_RESPONSE,
        payload: collectedResponses,
      };
    })
  );
});

export const fetchSelectedOrderEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_SELECTED_ORDER_REQUEST),
    mergeMap(action => from(AirshopApi.get(`orders/full/${action.payload}`))
      .pipe(
          map(res => res.data && res.status === 200 ? res.data : null),
          map(order => actions.ordersSelectedOrderResponse(order)),
          catchError(error => of(actions.ordersSelectedOrderFailure(error))
          ),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_SELECTED_ORDER_RESPONSE ||
              actionTypes.ORDERS_SELECTED_ORDER_FAILURE
          ))
      )
    )
  );

export const updateStavObjednavkyEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_UPDATE_STAV_OBJEDNAVKY_REQUEST),
    mergeMap(action => {
      const { orderId, ordersStateId, sessionId } = action.payload;
      return from(AirshopApi.get('orders/stavObjednavky', {
      params: {
        ident: sessionId,
        orderId,
        stavObjednavkyId: ordersStateId,
      }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(stavObjednavky => {
            return {
              type: actionTypes.ORDERS_UPDATE_STAV_OBJEDNAVKY_RESPONSE,
              payload: {response: stavObjednavky, resMessage: actionTypes.ORDERS_UPDATE_STAV_OBJEDNAVKY_RESPONSE},
            }
          }),
          catchError(error => of({
              type: actionTypes.ORDERS_UPDATE_STAV_OBJEDNAVKY_FAILURE,
              payload: { error, resMessage: actionTypes.ORDERS_UPDATE_STAV_OBJEDNAVKY_FAILURE },
            })
          ),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_UPDATE_STAV_OBJEDNAVKY_RESPONSE ||
              actionTypes.ORDERS_UPDATE_STAV_OBJEDNAVKY_FAILURE
          ))
      )
    })
  );

export const fetchOrderFirmEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_FIRM_REQUEST),
    mergeMap(action => from(AirshopApi.get('firms/abraId', {
      params: {
        abraId: action.payload,
      }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(firm => {
            return {
              type: actionTypes.ORDERS_FIRM_RESPONSE,
              payload: firm,
            }
          }),
          catchError(error => of({
              type: actionTypes.ORDERS_FIRM_FAILURE,
              payload: error,
            })
          ),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_FIRM_RESPONSE ||
              actionTypes.ORDERS_FIRM_FAILURE
          ))
      )
    )
  );

export const fetchOrdersProductsForPdfListEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_FOR_PDF_REQUEST),
    mergeMap((action) => {
      const { ordersIds, ordersFirmIds } = action.payload;
      // Used to fetch related storeCards later
      const productsArr = [];

      // Will be passed to payload
      const productsByOrderIdArr = [];
      const firmsByOrderIdArr = [];
      const storeCardsArr = [];

      return getProductsForkJoin(ordersIds, productsArr, productsByOrderIdArr)
      .pipe(
        $fetchOrdersFirms(ordersIds, ordersFirmIds, firmsByOrderIdArr),
        $fetchStoreCards(productsArr, storeCardsArr),
        map(() => ({
            type: actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_FOR_PDF_RESPONSE,
            payload: {
              products: productsByOrderIdArr,
              firms: firmsByOrderIdArr,
              storeCards: storeCardsArr,
            }
        })),
        catchError((error) =>
          of({
            type: actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_FOR_PDF_FAILURE,
            payload: error,
          })
        ),
        takeUntil(
          action$.ofType(
            actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_FOR_PDF_RESPONSE ||
              actionTypes.ORDERS_ORDER_DETAIL_PRODUCTS_FOR_PDF_FAILURE
          )
        )
      );
    })
  );

const getProductsForkJoin = (ordersIds, productsArr, productsByOrderIdArr) => {
  return forkJoin(
    ordersIds.map((id) =>
      AirshopApi.get(`orderItems/ordersId/${id}`, {
        params: {
          limit: 500, // logic from previous AngularJS app
          order: 'rowtype',
          sort: 'DESC',
        },
      }).then((res) => {
        // products stored also individually for fetching their store card
        if (res.data.status === 'OK') {
          const items = res.data.items;
          items.forEach(product => {
            const productAlreadyStored = !!_.find(productsArr, { id: product.id }); // boolean
            if (!productAlreadyStored) productsArr.push(product);
          });
          productsByOrderIdArr[id] = items;
        }
      })
    )
  )
}

const $fetchOrdersFirms = (ordersIds, orderFirmIds, firmsArr) => {
  return mergeMap(() => {
    return forkJoin(
    ordersIds.map((orderId) => AirshopApi.get('firms/abraId', {
      params: {
        abraId: orderFirmIds[orderId],
      },
    })
    .then(
      res => {
        if (res.data.status === 'OK') firmsArr[orderId] = res.data.data;
      }
    ))
  )
  .pipe(
    map(() => firmsArr)
  )})
}

const $fetchStoreCards = (productsArr, storeCardsArr) => {
  return mergeMap(() => $getRelatedStoreCardsForkJoin(productsArr).pipe(
    map(response => {
      storeCardsArr.push(...response.map(item => item.data.data));
      return response;
    })
  ))
}
export const sendOrderToStavSms = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_ORDER_TO_STAV_SEND_SMS_REQUEST),
    mergeMap(action => {
      const { orderId, stavObjednavkyId } = action.payload;
      return from(AirshopApi.get('orders/sendSMSByOrderId', {
      params: {
        orderId,
        stavObjednavkyId,
      }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => {
            return {
              type: actionTypes.ORDERS_ORDER_TO_STAV_SEND_SMS_RESPONSE,
              payload: { resMessage: notificationTypes.SEND_F_SUCCESS },
            }
          }),
          catchError(error => of({
              type: actionTypes.ORDERS_ORDER_TO_STAV_SEND_SMS_FAILURE,
              payload: { resMessage: notificationTypes.SEND_F_FAILURE, error},
            })
          ),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_ORDER_TO_STAV_SEND_SMS_RESPONSE ||
              actionTypes.ORDERS_ORDER_TO_STAV_SEND_SMS_FAILURE
          ))
      )
    })
  );

export const sendOrderToStavEmail = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_ORDER_TO_STAV_SEND_EMAIL_REQUEST),
    mergeMap(action => {
      const { orderId, stavObjednavkyId } = action.payload;
      return from(AirshopApi.get('orders/sendEmailByOrderId', {
      params: {
        orderId,
        stavObjednavkyId,
      }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => {
            return {
              type: actionTypes.ORDERS_ORDER_TO_STAV_SEND_EMAIL_RESPONSE,
              payload: { resMessage: notificationTypes.SEND_M_SUCCESS },
            }
          }),
          catchError(error => of({
              type: actionTypes.ORDERS_ORDER_TO_STAV_SEND_EMAIL_FAILURE,
              payload: { resMessage: notificationTypes.SEND_M_FAILURE, error},
            })
          ),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_ORDER_TO_STAV_SEND_EMAIL_RESPONSE ||
              actionTypes.ORDERS_ORDER_TO_STAV_SEND_EMAIL_FAILURE
          ))
      )
    })
  );

  // Partner messages are formed from:
  // - partner message of an order with fetched partner object as its prop
  // - status logs of on order
export const fetchOrderPartnerMessagesEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_PARTNER_MESSAGES_REQUEST),
    mergeMap(action => {
      const messages = [];
      // FETCH MESSAGES
      return from(AirshopApi.get('partnerMessage/ordersId', {
      params: {
        id: action.payload,
        order: 'date_created',
        sort: 'ASC'
      }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          // FETCH PARTNERS OF MESSAGES by message authorPartnerId
          mergeMap(partnerMessages => {
            const containsMessages = !!partnerMessages.length;

            if (containsMessages) {
              const messagesByUniquePartnerIds = _.uniqBy(
                partnerMessages,
                'authorPartnerId'
              );
              const uniqueMessagePartnerIds = messagesByUniquePartnerIds.map(message => message.authorPartnerId);
              return forkJoin(
                uniqueMessagePartnerIds.map(partnerId =>
                  AirshopApi.get(`auth/partners/${partnerId}`)
                )
              ).pipe(
                // MAP THE FETCHED PARTNERS TO THE MESSAGES as its prop
                map(partnersRes => {
                  const partners = partnersRes.map(item => item.data);
                  const messagesWithPartners = partnerMessages.map(message => {
                    if (message.authorPartnerId) {
                      const partnerOfMessage = _.find(partners, { 'id': message.authorPartnerId});
                      message.mappedPartner = partnerOfMessage;
                    }
                    return message;
                  });
                  return messagesWithPartners;
                })
              );
            } else {
              return of([])
            }
          }),
          // FETCH STATUS LOGS
          mergeMap(partnerMessagesMappedToPartners => {
            messages.push(...partnerMessagesMappedToPartners);
            return from(AirshopApi.get('orderStatusLog/ordersId', {
            params: {
              id: action.payload,
              order: 'date_created',
              sort: 'ASC'
            }
          })).pipe(
            map(res => res.data.status === 'OK' ? res.data.items : null)
          )}),
          // MAP STATUS LOGS
          map(statusLogs => {
            const statusLogsMapped = statusLogs.map(item => {
              item.email = item.system;
              item.message = item.statusText;
              item.isStatusLog = true;
              return item;
            });
            messages.push(...statusLogsMapped);
            const sortedMessages = _.sortBy(messages, 'dateCreated' );
          // RETURN SORTED MESSAGES
            return {
              type: actionTypes.ORDERS_PARTNER_MESSAGES_RESPONSE,
              payload: sortedMessages,
            }
          }),
          catchError(error => of({
              type: actionTypes.ORDERS_PARTNER_MESSAGES_FAILURE,
              payload: error,
            })
          ),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_PARTNER_MESSAGES_RESPONSE ||
              actionTypes.ORDERS_PARTNER_MESSAGES_FAILURE
          ))
      )
    })
  );

export const updateOrderItemsQuantityEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_ORDER_ITEM_UPDATE_QUANTITY_REQUEST),
    mergeMap(action => from(AirshopApi.get('orderItems/quantity', {
      params: {
        ident: action.payload.ident,
        orderItemsId: action.payload.orderItemId,
        quantity: action.payload.quantity,
      }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(res => {
            return {
              type: actionTypes.ORDERS_ORDER_ITEM_UPDATE_QUANTITY_RESPONSE,
              payload: { resMessage: notificationTypes.CHANGE_PRODUCT_Q_SUCCESS },
            }
          }),
          catchError(error => of({
              type: actionTypes.ORDERS_ORDER_ITEM_UPDATE_QUANTITY_FAILURE,
              payload: { error, resMessage: notificationTypes.CHANGE_PRODUCT_Q_FAILURE },
            })
          ),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_ORDER_ITEM_UPDATE_QUANTITY_RESPONSE ||
              actionTypes.ORDERS_ORDER_ITEM_UPDATE_QUANTITY_FAILURE
          ))
      )
    )
  );

export const fetchAddressEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_ADDRESS_REQUEST),
    mergeMap(action => {
      const { firmAbraId, firmOfficeAbraId } = action.payload;

      return from(
        AirshopApi.get('firmOffices/abraId', {
          params: {
            abraId: firmOfficeAbraId,
          },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res))),
        $fetchAddresses(firmAbraId),
        map((addressesArr) => {
          const address = _.find(addressesArr, { abraId: firmAbraId});
          const officeAdress = _.reject(addressesArr, { abraId: firmAbraId})[0];
          const addresses = { address, officeAdress };
          return {
            type: actionTypes.ORDERS_ADDRESS_RESPONSE,
            payload: addresses,
          };
        }),
        catchError((error) =>
          of({
            type: actionTypes.ORDERS_ADDRESS_FAILURE,
            payload: error,
          })
        ),
        takeUntil(
          action$.ofType(
            actionTypes.ORDERS_ADDRESS_RESPONSE ||
              actionTypes.ORDERS_FIRM_FAILURE
          )
        )
      );
    })
  );

const $fetchAddresses = firmAbraId => {
  return mergeMap((firmOffice) =>
    forkJoin(
      AirshopApi.get('addresses/abraId', {
        params: {
          abraId: firmAbraId,
        },
      }),
      AirshopApi.get('addresses/abraId', {
        params: {
          abraId: firmOffice.addressId,
        },
      })
    ).pipe(
      map((response) => {
        return response
          ? response.map((item) =>
              item.data.status === 'OK' ? item.data.data : null
            )
          : null;
      })
    )
  )
}

export const fetchOrderPartnerEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_PARTNER_REQUEST),
    mergeMap(action => from(CraftApi.get(`partners/${action.payload}`))
      .pipe(
          map(res => res.status === 200 ? res.data : err(errMessages.NOT_200, res)),
          map(partner => {
            return {
              type: actionTypes.ORDERS_PARTNER_RESPONSE,
              payload: { partnerId: partner.id, partner },
            }
          }),
          catchError(error => of({
              type: actionTypes.ORDERS_PARTNER_FAILURE,
              payload: error,
            })
          ),
          takeUntil(action$.ofType(actionTypes.ORDERS_PARTNER_CLOSE_STREAM))
      )
    )
  );

export const deleteOrderProductEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_ORDER_ITEM_DELETE_REQUEST),
    mergeMap(action => from(AirshopApi.delete('orderItems', {
      params: {
        ident: action.payload.ident,
        orderItemsId: action.payload.productId,
      }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(res => {
            return {
              type: actionTypes.ORDERS_ORDER_ITEM_DELETE_RESPONSE,
              payload: { resMessage: notificationTypes.DELETE_SUCCESS },
            }
          }),
          catchError(error => of({
              type: actionTypes.ORDERS_ORDER_ITEM_DELETE_FAILURE,
              payload: { error, resMessage: notificationTypes.DELETE_FAILURE },
            })
          ),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_ORDER_ITEM_DELETE_RESPONSE ||
              actionTypes.ORDERS_ORDER_ITEM_DELETE_FAILURE
          ))
      )
    )
  );

export const createOrderPartnerMessageEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_NEW_PARTNER_MESSAGE_REQUEST),
    mergeMap(action => from(AirshopApi.post('partnerMessage', action.payload))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(res => {
            return {
              type: actionTypes.ORDERS_NEW_PARTNER_MESSAGE_RESPONSE,
              payload: { resMessage: notificationTypes.SEND_F_SUCCESS },
            }
          }),
          catchError(error => of({
              type: actionTypes.ORDERS_NEW_PARTNER_MESSAGE_FAILURE,
              payload: { error, resMessage: notificationTypes.SEND_F_FAILURE },
            })
          ),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_NEW_PARTNER_MESSAGE_RESPONSE ||
              actionTypes.ORDERS_NEW_PARTNER_MESSAGE_FAILURE
          ))
      )
    )
  );

export const syncOrderWithAbraEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_ORDER_SYNC_ABRA_REQUEST),
    mergeMap((action) =>
      from(AirshopApi.get('fc/fcOrdersSync/syncExternal', {
            params: {
              externalNumber: action.payload,
            },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res))),
        map((res) => {
          return {
            type: actionTypes.ORDERS_ORDER_SYNC_ABRA_RESPONSE,
            payload: { resMessage: notificationTypes.SYNC_F_SUCCESS },
          };
        }),
        catchError((error) =>
          of({
            type: actionTypes.ORDERS_ORDER_SYNC_ABRA_FAILURE,
            payload: { error, resMessage: notificationTypes.SYNC_F_FAILURE },
          })
        ),
        takeUntil(
          action$.ofType(
            actionTypes.ORDERS_ORDER_SYNC_ABRA_RESPONSE ||
              actionTypes.ORDERS_ORDER_SYNC_ABRA_FAILURE
          )
        )
      )
    )
  );

export const createOrderDeliveryListEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_CREATE_DELIVERY_LIST_REQUEST),
    mergeMap((action) =>
      from(AirshopApi.get('deliveryList/createFromOrder', {
            params: {
              id: action.payload.orderId,
              ident: action.payload.ident,
            },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res))),
        map((res) => {
          return {
            type: actionTypes.ORDERS_CREATE_DELIVERY_LIST_RESPONSE,
            payload: { resMessage: notificationTypes.DL_CREATE_SUCCESS },
          };
        }),
        catchError((error) =>
          of({
            type: actionTypes.ORDERS_CREATE_DELIVERY_LIST_FAILURE,
            payload: { error, resMessage: notificationTypes.DL_CREATE_FAILURE },
          })
        ),
        takeUntil(
          action$.ofType(
            actionTypes.ORDERS_CREATE_DELIVERY_LIST_RESPONSE ||
              actionTypes.ORDERS_CREATE_DELIVERY_LIST_FAILURE
          )
        )
      )
    )
  );

export const recreateOrderDeliveryListEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_RECREATE_DELIVERY_LIST_REQUEST),
    mergeMap((action) =>
      from(AirshopApi.get('deliveryList/readDeliveryListPdf', {
            params: {
              id: action.payload,
            },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res))),
        map((res) => {
          return {
            type: actionTypes.ORDERS_RECREATE_DELIVERY_LIST_RESPONSE,
            payload: { resMessage: notificationTypes.DL_RECREATE_SUCCESS },
          };
        }),
        catchError((error) =>
          of({
            type: actionTypes.ORDERS_RECREATE_DELIVERY_LIST_FAILURE,
            payload: { error, resMessage: notificationTypes.DL_RECREATE_FAILURE },
          })
        ),
        takeUntil(
          action$.ofType(
            actionTypes.ORDERS_RECREATE_DELIVERY_LIST_RESPONSE ||
              actionTypes.ORDERS_RECREATE_DELIVERY_LIST_FAILURE
          )
        )
      )
    )
  );

export const fetchOrderDeliveryListEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_ORDER_DELIVERY_LIST_REQUEST),
    mergeMap((action) =>
      from(AirshopApi.get('deliveryList/ordersId', {
            params: {
              id: action.payload,
            },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res))),
        map((deliveryList) => {
          return {
            type: actionTypes.ORDERS_ORDER_DELIVERY_LIST_RESPONSE,
            payload: deliveryList[0] ? deliveryList[0] : null,
          };
        }),
        catchError((error) =>
          of({
            type: actionTypes.ORDERS_ORDER_DELIVERY_LIST_FAILURE,
            payload: error,
          })
        ),
        takeUntil(
          action$.ofType(
            actionTypes.ORDERS_ORDER_DELIVERY_LIST_RESPONSE ||
              actionTypes.ORDERS_ORDER_DELIVERY_LIST_FAILURE
          )
        )
      )
    )
  );

export const createOrderItemEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_ORDER_CREATE_ITEM_REQUEST),
    mergeMap((action) => from(
        AirshopApi.post('orderItems', action.payload.body,
        {
          params: {
            ...action.payload.params,
          },
        })
      ).pipe(
        map((res) => (res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res))),
        map((response) =>
          actions.ordersAddOrderItemResponse(
            response,
            actionTypes.ORDERS_ORDER_CREATE_ITEM_RESPONSE
          )
        ),
        catchError((error) =>
          of(
            actions.ordersAddOrderItemFailure(
              error,
              actionTypes.ORDERS_ORDER_CREATE_ITEM_FAILURE
            )
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.ORDERS_ORDER_CREATE_ITEM_RESPONSE ||
              actionTypes.ORDERS_ORDER_CREATE_ITEM_FAILURE
          )
        )
      )
    )
  );

export const checkOrderSyncStatusesEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_ORDER_SYNC_STATUSES_CHECK_REQUEST),
    mergeMap((action) => {
      const { externalNumber } = action.payload;
      const statuses = {
        [externalNumber]: {
          dbAbra: false,
          schemaFC: false,
      }};

      return from(
        AirshopApi.get(`abraReceivedorders/externalNumber/${externalNumber}`)
      ).pipe(
        map((res) => res.status === 200 ? res : err(errMessages.NOT_200, res)),
        map((response) => {
          if (response.data && response.data.id) {
            statuses[externalNumber].dbAbra = true;
          }
          return response;
        }),
        mergeMap(() => from(AirshopApi.get(`fcOrders/orderNumber/${externalNumber}`))
        .pipe(
          map(response => response.data ? response.data : null),
          map(response => {
            if (response.status === 'OK') {
              statuses[externalNumber].schemaFC = true;
            }
            return response
          })
        )),
        map(response => actions.ordersOrderSyncStatusesCheckResponse(statuses)),
        catchError((error) =>
          of(actions.ordersOrderSyncStatusesCheckFailure(error))
        ),
        takeUntil(
          action$.ofType(
            actionTypes.ORDERS_ORDER_SYNC_STATUSES_CHECK_RESPONSE ||
              actionTypes.ORDERS_ORDER_SYNC_STATUSES_CHECK_FAILURE
          )
        )
      )
    })
  );

export const syncWithAbraWhileInAbraEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_SYNC_ABRA_WHILE_IN_ABRA_REQUEST),
    mergeMap((action) => from(
        AirshopApi.get('sync/order/updateExternal', {
          params: {
            externalNumber: action.payload,
          },
        })
      ).pipe(
        map((res) => (res.status === 200 ? res : err(errMessages.NOT_200, res))),
        map((response) => {
          let resMessage = '';
          if (response.data && response.data.status === 'OK') {
            resMessage = actionTypes.ORDERS_SYNC_ABRA_WHILE_IN_ABRA_RESPONSE;
          } else {
            resMessage = actionTypes.ORDERS_SYNC_ABRA_WHILE_IN_ABRA_FAILURE;
          }
          return actions.ordersOrderSyncWithAbraWhileInAbraResponse(
            response,
            resMessage
          )
        }),
        catchError((error) =>
          of(
            actions.ordersOrderSyncWithAbraWhileInAbraFailure(
              error,
              actionTypes.ORDERS_SYNC_ABRA_WHILE_IN_ABRA_FAILURE
            )
          )
        ),
        takeUntil(
          action$.ofType(
            actionTypes.ORDERS_SYNC_ABRA_WHILE_IN_ABRA_RESPONSE ||
              actionTypes.ORDERS_SYNC_ABRA_WHILE_IN_ABRA_FAILURE
          )
        )
      )
    )
  );

export const printDocumentEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_PRINT_DOCUMENT_REQUEST),
    mergeMap(action => {
      const { type, itemId, userUid, ident } = action.payload;

      return from(AirshopApi.get('print/p/printA4', {
        params: {
          type,
          id: itemId,
          uid: userUid,
          ident,
        }
      }))
      .pipe(
          map(response => response.data.status === 'OK' ? response : err(errMessages.NOT_OK, response)),
          map(response => actions.ordersPrintDocumentResponse(
            response,
            actionTypes.ORDERS_PRINT_DOCUMENT_RESPONSE
          )),
          catchError(error => of(actions.ordersPrintDocumentFailure(
            error,
            actionTypes.ORDERS_PRINT_DOCUMENT_FAILURE
          ))
          ),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_PRINT_DOCUMENT_RESPONSE ||
              actionTypes.ORDERS_PRINT_DOCUMENT_FAILURE
          ))
      )
    })
  );

export const printBalikobotEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_PRINT_BALIKOBOT_REQUEST),
    mergeMap(action => {
      const { orderId, userUid, ident } = action.payload;
      return from(AirshopApi.get('print/p/printThermo', {
        params: {
          uid: userUid,
          ident,
          orderId,
        }
      }))
      .pipe(
          map(response => response.data.status === 'OK' ? response : err(errMessages.NOT_OK, response)),
          map(response => actions.ordersPrintBalikobotResponse(
            response,
            actionTypes.ORDERS_PRINT_BALIKOBOT_RESPONSE
          )),
          catchError(error => of(actions.ordersPrintBalikobotFailure(
            error,
            actionTypes.ORDERS_PRINT_BALIKOBOT_FAILURE
          ))
          ),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_PRINT_BALIKOBOT_RESPONSE ||
              actionTypes.ORDERS_PRINT_BALIKOBOT_FAILURE
          ))
      )
    })
  );

export const printThermal80Epic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_PRINT_THERMAL80_REQUEST),
    mergeMap(action => {
      const { type, orderId, userUid, ident } = action.payload;
      return from(AirshopApi.get('print/p/printThermal80', {
        params: {
          type: type,
          uid: userUid,
          ident,
          orderId,
        }
      }))
      .pipe(
          map(response => response.data.status === 'OK' ? response : err(errMessages.NOT_OK, response)),
          map(response => actions.ordersPrintBalikobotResponse(
            response,
            actionTypes.ORDERS_PRINT_THERMAL80_RESPONSE
          )),
          catchError(error => of(actions.ordersPrintBalikobotFailure(
            error,
            actionTypes.ORDERS_PRINT_THERMAL80_FAILURE
          ))
          ),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_PRINT_THERMAL80_RESPONSE ||
              actionTypes.ORDERS_PRINT_THERMAL80_FAILURE
          ))
      )
    })
  );

export const massPrintDocumentsEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_MASS_PRINT_DOCUMENTS_REQUEST),
    mergeMap(action => {
      const { type, itemIds, userUid, ident } = action.payload;

      return from(AirshopApi.get('print/p/massPrintA4', {
        params: {
          type,
          id: itemIds,
          uid: userUid,
          ident,
        }
      }))
      .pipe(
          map(response => response.data.status === 'OK' ? response : err(errMessages.NOT_OK, response)),
          map(response => actions.ordersMassPrintDocumentsResponse(
            response,
            actionTypes.ORDERS_MASS_PRINT_DOCUMENTS_RESPONSE
          )),
          catchError(error => of(actions.ordersMassPrintDocumentsFailure(
            error,
            actionTypes.ORDERS_MASS_PRINT_DOCUMENTS_FAILURE
          ))
          ),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_MASS_PRINT_DOCUMENTS_RESPONSE ||
            actionTypes.ORDERS_MASS_PRINT_DOCUMENTS_FAILURE
          ))
      )
    })
  );

export const generatePdfDocumentEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_GENERATE_PDF_REQUEST),
    mergeMap(action => {
      const { type, itemId, userUid, ident } = action.payload;

      return from(AirshopApi.get(
        `print/p/document?type=${type}&id=${itemId}&uid=${userUid}&ident=${ident}`,
        {responseType: 'blob'}))
      .pipe(
        // text/xml => success, application/json => failure
        map(response => response.status === 200 && response.data && response.data.type === 'text/xml' ?
                        response.data :
                        err(errMessages.DATA_NOT_GENERATED, response.data)
        ),
        map(response => actions.ordersGeneratePdfDocumentResponse(
          response,
          actionTypes.ORDERS_GENERATE_PDF_RESPONSE
        )),
        catchError(error => of(actions.ordersGeneratePdfDocumentFailure(
          error,
          actionTypes.ORDERS_GENERATE_PDF_FAILURE
        ))
        ),
        takeUntil(action$.ofType(
          actionTypes.ORDERS_GENERATE_PDF_RESPONSE ||
            actionTypes.ORDERS_GENERATE_PDF_FAILURE
        ))
      )
    })
  );

export const massGeneratePdfDocumentEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_MASS_GENERATE_PDF_REQUEST),
    mergeMap(action => {
      const { type, itemIds, userUid, ident } = action.payload;

      return from(AirshopApi.get(
        `print/p/massDocument?type=${type}&id=${itemIds}&uid=${userUid}&ident=${ident}`,
        {responseType: 'blob'}))
      .pipe(
        // text/xml => success, application/json => failure
        map(response => response.status === 200 && response.data && response.data.type === 'text/xml' ?
                        response.data :
                        err(errMessages.DATA_NOT_GENERATED, response.data)
        ),
        map(response => actions.ordersMassGeneratePdfDocumentResponse(
          response,
          actionTypes.ORDERS_MASS_GENERATE_PDF_RESPONSE
        )),
        catchError(error => of(actions.ordersMassGeneratePdfDocumentFailure(
          error,
          actionTypes.ORDERS_MASS_GENERATE_PDF_FAILURE
        ))
        ),
        takeUntil(action$.ofType(
          actionTypes.ORDERS_MASS_GENERATE_PDF_RESPONSE ||
            actionTypes.ORDERS_MASS_GENERATE_PDF_FAILURE
        ))
      )
    })
  );

export const updateBulkOrdersStavObjednavkyEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_REQUEST),
    mergeMap(action => {
      const { ordersIds, ordersStateId, sessionId } = action.payload;
      return forkJoin(ordersIds.map(orderId => AirshopApi.get('orders/stavObjednavky', {
        params: {
          ident: sessionId,
          orderId,
          stavObjednavkyId: ordersStateId,
        }
      })))
      .pipe(
        map((response) => {
          let hasErrored = false;
          const erroredResponses = [];

          // Finding out, whether all forkJoin reqs were successes
          if (response) {
            response.forEach(singleRes => {
              if (singleRes.data.status !== 'OK') {
                hasErrored = true;
                erroredResponses.push(singleRes);
              }
            });
          } else {
            hasErrored = true;
          }

          // If not, trigger catchError; otherwise, continue with the stream
          if (hasErrored) {
            err(
              'Bulk orders state update not successful, see responses that caused the error!',
              erroredResponses
            )
          } else {
            return response;
          }
        }),
          map(response => actions.ordersBulkOrdersUpdateStavObjednavkyResponse(
            response, actionTypes.ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_RESPONSE
          )),
          catchError(error => of(actions.ordersBulkOrdersUpdateStavObjednavkyFailure(
            error, actionTypes.ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_FAILURE
          ))
          ),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_RESPONSE ||
              actionTypes.ORDERS_UPDATE_BULK_ORDERS_STAV_OBJEDNAVKY_FAILURE
          ))
      )
    })
  );

export const createCollectionListItemFromOrderEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_CREATE_CLITEM_FROM_ORDER_REQUEST),
    mergeMap(action => {
      const { orderId, collectionListId } = action.payload;
      return from(AirshopApi.get('balikobot/collectionListItem/createFromOrder', {
      params: { ordersId: orderId, collectionListId }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data : err(errMessages.NOT_OK, res)),
          map(response => actions.ordersCreateCLItemFromOrderResponse(
            response,
            actionTypes.ORDERS_CREATE_CLITEM_FROM_ORDER_RESPONSE
          )),
          catchError(error => of(actions.ordersCreateCLItemFromOrderFailure(
            error,
            actionTypes.ORDERS_CREATE_CLITEM_FROM_ORDER_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_CREATE_CLITEM_FROM_ORDER_RESPONSE ||
            actionTypes.ORDERS_CREATE_CLITEM_FROM_ORDER_FAILURE
          ))
      )
    })
  );


  export const fetchCollectionListQueuedEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_COLLECTION_LIST_QUEUED_REQUEST),
    mergeMap(action => from(AirshopApi.get('balikobot/collectionList/queued', {
        params: { limit: 50 }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(queuedCollectionLists => actions.ordersCollectionListQueuedResponse(queuedCollectionLists)),
          catchError(error => of(actions.ordersCollectionListQueuedFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_COLLECTION_LIST_QUEUED_RESPONSE ||
            actionTypes.ORDERS_COLLECTION_LIST_QUEUED_FAILURE
          ))
      )
    )
  );

export const createBalikobotOrderEntryEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_REQUEST),
    mergeMap(action => from(AirshopApi.get('balikobot/balikobotOrder/fromOrder', {
        params: {
          id: action.payload.orderId,
          ident: action.payload.ident,
        }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => actions.ordersCreateBalikobotOrderEntryResponse(
            {[action.payload.orderId]: response},
            actionTypes.ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_RESPONSE
          )),
          catchError(error => of(actions.ordersCreateBalikobotOrderEntryFailure(
            error,
            actionTypes.ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_RESPONSE ||
            actionTypes.ORDERS_CREATE_BALIKOBOT_ORDER_ENTRY_FAILURE
          ))
      )
    )
  );

export const fetchBalikobotOrderEntryEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_BALIKOBOT_ORDER_ENTRY_REQUEST),
    mergeMap(action => from(AirshopApi.get('balikobot/balikobotOrder/orderId', {
        params: { orderId: action.payload.orderId }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => {
            const { isOrderSubscription } = action.payload
            const { balikobotOrderEntry } = state$.value.orders
            const updatedBalikobotOrderEntry = isOrderSubscription
            && balikobotOrderEntry ? {...balikobotOrderEntry} : {}
            updatedBalikobotOrderEntry[action.payload.orderId] = response
            return actions.ordersBalikobotOrderEntryResponse(
              updatedBalikobotOrderEntry
          )}),
          catchError(error => of(actions.ordersBalikobotOrderEntryFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_BALIKOBOT_ORDER_ENTRY_RESPONSE ||
            actionTypes.ORDERS_BALIKOBOT_ORDER_ENTRY_FAILURE
          ))
      )
    )
  );

export const fetchMultipleBalikobotOrderEntryEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_MULTIPLE_BALIKOBOT_ORDER_ENTRY_REQUEST),
    mergeMap(action => {
      const { ordersIds } = action.payload
      return forkJoin(ordersIds.map(orderId => AirshopApi.get('balikobot/balikobotOrder/orderId', {
        params: { orderId }
      })))
      .pipe(
        map(responses => passOnlySuccessForkJoinResponses(responses)),
        map(successResponses => {
          const obj = {}
          successResponses.length && successResponses.forEach(res => obj[res.config?.params?.orderId] = res.data.data)
          return obj
        }),
        map(balikobotOrderEntries => actions.ordersMultipleBalikobotOrderEntryResponse(
        balikobotOrderEntries
        )),
        catchError(error => of(actions.ordersMultipleBalikobotOrderEntryFailure(
          error
        ))),
        takeUntil(action$.ofType(
          actionTypes.ORDERS_MULTIPLE_BALIKOBOT_ORDER_ENTRY_RESPONSE ||
          actionTypes.ORDERS_MULTIPLE_BALIKOBOT_ORDER_ENTRY_FAILURE
        ))
      )
    })
  );

export const updateBalikobotOrderEntryEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_REQUEST),
    mergeMap(action => {
      const { balikobotOrderEntry } = action.payload;
      return from(AirshopApi.put('balikobot/balikobotOrder',
        balikobotOrderEntry,
        { params: { balikobotOrderId: balikobotOrderEntry.id } }
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(response => {
            const { isOrderSubscription } = action.payload
            const { balikobotOrderEntry } = state$.value.orders
            const updatedBalikobotOrderEntry = isOrderSubscription
            && balikobotOrderEntry ? {...balikobotOrderEntry} : {}
            updatedBalikobotOrderEntry[action.payload.orderId] = response
            return actions.ordersUpdateBalikobotOrderEntryResponse(
            {[action.payload.orderId]: response},
            actionTypes.ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_RESPONSE
          )}),
          catchError(error => of(actions.ordersUpdateBalikobotOrderEntryFailure(
            error,
            actionTypes.ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_RESPONSE ||
            actionTypes.ORDERS_UPDATE_BALIKOBOT_ORDER_ENTRY_FAILURE
          ))
      )
    })
  );


export const generateBalikobotTagEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_GENERATE_BALIKOBOT_TAG_REQUEST),
    mergeMap(action => {
      const { orderId, ident } = action.payload;
      return from(AirshopApi.get('balikobot/balikobotOrder/addOrderToBalikobot',
        { params: { id: orderId, ident } }
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data: err(errMessages.NOT_OK, res)),
          map(response => actions.ordersGenerateBalikobotTagResponse(
            {[orderId]: response},
            actionTypes.ORDERS_GENERATE_BALIKOBOT_TAG_RESPONSE
          )),
          catchError(error => of(actions.ordersGenerateBalikobotTagFailure(
            error,
            actionTypes.ORDERS_GENERATE_BALIKOBOT_TAG_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_GENERATE_BALIKOBOT_TAG_RESPONSE ||
            actionTypes.ORDERS_GENERATE_BALIKOBOT_TAG_FAILURE
          ))
      )
    })
  );

export const dropOrderFromBalikobotEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_DROP_ORDER_FROM_BALIKOBOT_REQUEST),
    mergeMap(action => {
      const { orderId, ident } = action.payload;
      return from(AirshopApi.get('balikobot/balikobotOrder/dropOrderFromBalikobot',
        { params: { id: orderId, ident } }
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data: err(errMessages.NOT_OK, res)),
          map(response => actions.ordersDropOrderFromBalikobotResponse(
            {[orderId]: response},
            actionTypes.ORDERS_DROP_ORDER_FROM_BALIKOBOT_RESPONSE
          )),
          catchError(error => of(actions.ordersDropOrderFromBalikobotFailure(
            error,
            actionTypes.ORDERS_DROP_ORDER_FROM_BALIKOBOT_FAILURE
          ))),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_DROP_ORDER_FROM_BALIKOBOT_RESPONSE ||
            actionTypes.ORDERS_DROP_ORDER_FROM_BALIKOBOT_FAILURE
          ))
      )
    })
  );

export const createCLItemsFromMultipleOrdersEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_REQUEST),
    mergeMap(action => {
      const { ordersIds, collectionListId } = action.payload;
      return forkJoin(ordersIds.map(orderId => AirshopApi.get('balikobot/collectionListItem/createFromOrder', {
        params: { ordersId: orderId, collectionListId }
      })))
      .pipe(
        map((response) => {
          let hasErrored = false;
          const erroredResponses = [];

          // Finding out, whether all forkJoin reqs were successes
          if (response) {
            response.forEach(singleRes => {
              if (singleRes.data.status !== 'OK') {
                hasErrored = true;
                erroredResponses.push(singleRes);
              }
            });
          } else {
            hasErrored = true;
          }

          // If not, trigger catchError; otherwise, continue with the stream
          if (hasErrored) {
            err(
              'Creating collection lists items has errored, see responses that caused the error!',
              erroredResponses
            )
          } else {
            return response;
          }
        }),
          map(response => actions.ordersCreateCLItemsFromMultipleOrderResponse(
            response, actionTypes.ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_RESPONSE
          )),
          catchError(error => of(actions.ordersCreateCLItemsFromMultipleOrderFailure(
            error, actionTypes.ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_FAILURE
          ))
          ),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_RESPONSE ||
            actionTypes.ORDERS_CREATE_CLITEMS_FROM_MULTIPLE_ORDERS_FAILURE
          ))
      )
    })
  );

export const fetchOrderDetailStatusLogsEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_ORDER_DETAIL_STATUS_LOGS_REQUEST),
    mergeMap(action => {
      const { orderId } = action.payload;
      return from(AirshopApi.get('orderStatusLog/ordersIdSystem',
        { params: { id: orderId, system: 'BALIKOBOT' } }
      ))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(response => actions.ordersOrderDetailStatusLogsResponse(
            { [orderId]: response }
          )),
          catchError(error => of(actions.ordersOrderDetailStatusLogsFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_ORDER_DETAIL_STATUS_LOGS_RESPONSE ||
            actionTypes.ORDERS_ORDER_DETAIL_STATUS_LOGS_FAILURE
          ))
      )
    })
  );

export const fetchBalikobotOrderBranchEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_BALIKOBOT_ORDER_BRANCH_REQUEST),
    mergeMap(action => {
      const { branchId, orderId } = action.payload;
      return from(AirshopApi.get(`balikobot/branch/${branchId}`))
      .pipe(
          map(res => res.status === 200 ? res.data : err(errMessages.NOT_200, res)),
          map(response => actions.ordersBalikobotOrderBranchResponse(
            { [orderId]: response }
          )),
          catchError(error => of(actions.ordersBalikobotOrderBranchFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_BALIKOBOT_ORDER_BRANCH_RESPONSE ||
            actionTypes.ORDERS_BALIKOBOT_ORDER_BRANCH_FAILURE
          ))
      )
    })
  );

export const fetchBranchesByShipperIdEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_BRANCHES_BY_SHIPPER_ID_REQUEST),
    mergeMap(action => {
      const { shipperId, search } = action.payload;
      return from(AirshopApi.get('balikobot/branch/shipperId', {
        params: {
          shipperId, search, page: 0, limit: 150,
        }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(response => actions.ordersBranchesByShipperIdResponse(
            { [shipperId]: response }
          )),
          catchError(error => of(actions.ordersBranchesByShipperIdFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_BRANCHES_BY_SHIPPER_ID_RESPONSE ||
            actionTypes.ORDERS_BRANCHES_BY_SHIPPER_ID_FAILURE
          ))
      )
    })
  );

export const fetchTransportationTypesValidDraftEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_TRANSPORTATION_TYPES_DRAFT_VALID_REQUEST),
    mergeMap(action => from(AirshopApi.get('transportationTypes/validDraft', {
      params: {
        limit: 100,
      }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(transTypes => actions.ordersTransportationTypesValidDraftResponse(transTypes)),
          catchError(error => of(actions.ordersTransportationTypesValidDraftFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_TRANSPORTATION_TYPES_DRAFT_VALID_RESPONSE ||
            actionTypes.ORDERS_TRANSPORTATION_TYPES_DRAFT_VALID_FAILURE
          ))
      )
    )
  );

export const fetchServiceTypesByShipperIdEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_SERVICE_TYPES_BY_SHIPPER_ID_REQUEST),
    mergeMap(action => {
      const { shipperId } = action.payload;
      return from(AirshopApi.get('balikobot/shipperServiceType/shipperId', {
        params: {
          shipperId, page: 0, limit: 150,
        }
      }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(response => {
            const filteredItems = _.filter(response, item => (
              moment().isAfter(item.showFrom) &&
              moment().isBefore(item.showTo) &&
              item.systemsAllowed.includes('DRAFT')
            ));
            return actions.ordersServiceTypesByShipperIdResponse(
              { [shipperId]: filteredItems }
            );
          }),
          catchError(error => of(actions.ordersServiceTypesByShipperIdFailure(
            error
          ))),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_SERVICE_TYPES_BY_SHIPPER_ID_RESPONSE ||
            actionTypes.ORDERS_SERVICE_TYPES_BY_SHIPPER_ID_FAILURE
          ))
      )
    })
  );

export const fetchOrdersCountByEmailEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_ORDERS_COUNT_BY_EMAIL_REQUEST),
    mergeMap(action => from(AirshopApi.get('orders/getCountByEmailSource', {
      params: {
        email: action.payload.email,
        source: action.payload.shop,
      }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
          map(count => actions.ordersOrdersCountByEmailResponse({
            [action.payload.email]: count
          })),
          catchError(error => of(actions.ordersOrdersCountByEmailFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_ORDERS_COUNT_BY_EMAIL_RESPONSE ||
            actionTypes.ORDERS_ORDERS_COUNT_BY_EMAIL_FAILURE
          ))
      )
    )
  );

export const fetchOrderAbraCommandEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_ORDER_ABRA_COMMAND_REQUEST),
    mergeMap(action => from(AirshopApi.get('abraCommands/orderId', {
      params: {
        orderId: action.payload.orderId,
      }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(abraCommands => {
            const erroredCommands =[..._(abraCommands)
                                    // .filter(item => item.progress.includes('400 Bad Request'))
                                    .orderBy(['dateCreated'], ['desc'])
                                    .slice(0, 5)];
            return actions.ordersOrderAbraCommandResponse({
            allCommands: {[action.payload.orderId]: abraCommands},
            lastFiveErroredCommands: {[action.payload.orderId]: erroredCommands},
          })
          }),
          catchError(error => of(actions.ordersOrderAbraCommandFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_ORDER_ABRA_COMMAND_RESPONSE ||
            actionTypes.ORDERS_ORDER_ABRA_COMMAND_FAILURE
          ))
      )
    )
  );

export const createSubscriptionEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_REQUEST),
    mergeMap(action => {
      const { orderId, index } = action.payload
      const { sessionId: ident, userUid: uid } = state$.value.auth
      return from(AirshopApi.get('orderSubscription/createSubscription', {
      params: { orderId, index, ident, uid }
    }))
      .pipe(
        map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
        mergeMap(response => concat(
          of(actions.ordersCreateOrderSubscriptionResponse(
            response,
            // Main order - index 0 or none, suborders - 1-6
            actionTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_RESPONSE + (index ? '_SUBORDER' : '')
          )),
          of(actions.ordersOrderSubscriptionsListRequest(orderId))
        )),
        catchError(error => of(actions.ordersCreateOrderSubscriptionFailure(
          error,
          actionTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_FAILURE
        ))),
        takeUntil(action$.ofType(
          actionTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_RESPONSE ||
          actionTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_RESPONSE + '_SUBORDER' ||
          actionTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_FAILURE
        ))
      )
    })
  );

// It creates orderSubscription and updates it with passed props right away
// Subsequently, it refetches the orderSubscriptions list
export const createSubscriptionWithUpdateEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_WITH_UPDATE_REQUEST),
    mergeMap(action => {
      const { orderId, index, objectToUpdateWith } = action.payload
      const { sessionId: ident, userUid: uid } = state$.value.auth
      return from(AirshopApi.get('orderSubscription/createSubscription',
      { params: { orderId, index, ident, uid } }
      ))
      .pipe(
        map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
        switchMap(createdSubOrderSubscription => from(AirshopApi.put(
          'orderSubscription',
          { ...createdSubOrderSubscription, ...objectToUpdateWith },
          { params: { orderSubscriptionId: createdSubOrderSubscription.id } }
        ))
          .pipe(
            map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
        )),
        mergeMap(response => concat(
          of(actions.ordersCreateOrderSubscriptionResponse(
            response,
            actionTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_RESPONSE + '_SUBORDER')),
          of(actions.ordersOrderSubscriptionsListRequest(orderId))
        )),
        catchError(error => of(actions.ordersCreateOrderSubscriptionFailure(
          error,
          actionTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_FAILURE
        ))),
        takeUntil(action$.ofType(
          actionTypes.ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_REQUEST ||
          actionTypes.ORDERS_CREATE_ORDER_SUBSCRIPTION_FAILURE
        ))
      )
    })
  );

export const fetchSubscriptionsListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_SUBSCRIPTIONS_LIST_REQUEST),
    mergeMap(action => from(AirshopApi.get('subscriptions'))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(response => actions.ordersSubscriptionsListResponse(response)),
          catchError(error => of(actions.ordersSubscriptionsListFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_SUBSCRIPTIONS_LIST_RESPONSE ||
            actionTypes.ORDERS_SUBSCRIPTIONS_LIST_FAILURE
          ))
      )
    )
  );

export const fetchOrderSubscriptionsListEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_REQUEST),
    mergeMap(action => {
      const { orderId } = action.payload
      return from(AirshopApi.get('orderSubscription/listBySourceOrderId', {
      params: { orderId }
    }))
      .pipe(
          map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
          map(response => actions.ordersOrderSubscriptionsListResponse({
            [orderId]: response
          })),
          catchError(error => of(actions.ordersOrderSubscriptionsListFailure(error))),
          takeUntil(action$.ofType(
            actionTypes.ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_RESPONSE ||
            actionTypes.ORDERS_ORDER_DETAIL_SUBSCRIPTIONS_LIST_FAILURE
          ))
      )
    })
  );

export const fetchSourceSubscriptionsByIndexEpic = action$ =>
action$.pipe(
  ofType(actionTypes.ORDERS_SOURCE_SUBSCRIPTIONS_BY_INDEX_REQUEST),
  mergeMap(action => {
    const { index, page, limit } = action.payload
    return from(AirshopApi.get('orderSubscription/listBySourceIndex', {
    params: { index, page, limit }
  }))
    .pipe(
        map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
        map(response => actions.ordersSourceSubscriptionsByIndexResponse({
          [index]: response
        })),
        catchError(error => of(actions.ordersSourceSubscriptionsByIndexFailure(error))),
        takeUntil(action$.ofType(
          actionTypes.ORDERS_SOURCE_SUBSCRIPTIONS_BY_INDEX_RESPONSE ||
          actionTypes.ORDERS_SOURCE_SUBSCRIPTIONS_BY_INDEX_FAILURE
        ))
    )
  })
);

export const createMultipleSubscriptionWithUpdateEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_REQUEST),
    mergeMap(action => {
      const { orderObjs, index } = action.payload
      const { sessionId: ident, userUid: uid } = state$.value.auth
      const ordersIds = Object.keys(orderObjs)
      // Test stream with swapi
      // return concat(...[1,2,3].map(orderId => Axios.get('https://swapi.dev/api/people/' + orderId
      // )))

      // Create new subscriptionOrders ...
      return concat(...ordersIds.map(orderId => AirshopApi.get('orderSubscription/createSubscription',
      { params: { orderId, index, ident, uid } }
      )))
      .pipe(
        toArray(),
        map(responses => handleForkJoinResponses(responses)),
        map(responses => responses.map(res => res.data.data)),
        // ... and update them right away with the passed props
        switchMap(orderSubscriptions => concat(
          ...orderSubscriptions.map(orderSubscription => AirshopApi.put(
            'orderSubscription',
            { ...orderSubscription, ...orderObjs[orderSubscription.sourceOrderId].objectToUpdateWith },
            { params: { orderSubscriptionId: orderSubscription.id } }
          ))
        ).pipe(
          toArray()
        )),
        map(responses => handleForkJoinResponses(responses)),
        map(responses => actions.ordersMultipleCreateOrderSubscriptionWithUpdateResponse(
          responses,
          actionTypes.ORDERS_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_RESPONSE
        )),
        catchError(error => of(actions.ordersMultipleCreateOrderSubscriptionWithUpdateFailure(
          error,
          actionTypes.ORDERS_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_FAILURE
        ))),
        takeUntil(action$.ofType(
          actionTypes.ORDERS_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_RESPONSE ||
          actionTypes.ORDERS_MULTIPLE_CREATE_ORDER_SUBSCRIPTION_FAILURE
        ))
      )
    })
  );


  export const deleteOrderSubscriptionEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_DELETE_ORDER_SUBSCRIPTION_REQUEST),
    mergeMap(action => {
      const { orderSubscriptionId, sourceOrderIdForRefetch } = action.payload
      const { userUid } = state$.value.auth;
      return from(AirshopApi.delete('orderSubscription', {
      params: { orderSubscriptionId, uid: userUid }
    }))
      .pipe(
        map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
        mergeMap(response => {
          const actionsArr = [
            actions.ordersDeleteOrderSubscriptionResponse(
              response,
              actionTypes.ORDERS_DELETE_ORDER_SUBSCRIPTION_RESPONSE
            )
          ]
          if (sourceOrderIdForRefetch) {
            actionsArr.push(
              actions.ordersOrderSubscriptionsListRequest(sourceOrderIdForRefetch)
            )
          }
          return actionsArr
        }),
        catchError(error => of(actions.ordersDeleteOrderSubscriptionFailure(error))),
        takeUntil(action$.ofType(
          sourceOrderIdForRefetch
            ? actionTypes.ORDERS_SUBSCRIPTIONS_LIST_REQUEST
            : actionTypes.ORDERS_DELETE_ORDER_SUBSCRIPTION_RESPONSE
          ||
          actionTypes.ORDERS_DELETE_ORDER_SUBSCRIPTION_FAILURE
        ))
      )
    })
  );

export const editOrderSubscriptionEpic = (action$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_EDIT_ORDER_SUBSCRIPTION_REQUEST),
    mergeMap(action => {
      const { updatedOrderSubscription, sourceOrderIdForRefetch } = action.payload
      return from(AirshopApi.put(
        'orderSubscription',
        updatedOrderSubscription,
        { params: { orderSubscriptionId: updatedOrderSubscription.id } }
      ))
      .pipe(
        map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
        mergeMap(response => {
          const actionsArr = [
            actions.ordersEditOrderSubscriptionResponse(
              response,
              actionTypes.ORDERS_EDIT_ORDER_SUBSCRIPTION_RESPONSE
            )
          ]
          if (sourceOrderIdForRefetch) {
            actionsArr.push(
              actions.ordersOrderSubscriptionsListRequest(sourceOrderIdForRefetch)
            )
          }
          return actionsArr
        }),
        catchError(error => of(actions.ordersEditOrderSubscriptionFailure(error))),
        takeUntil(action$.ofType(
          sourceOrderIdForRefetch
            ? actionTypes.ORDERS_SUBSCRIPTIONS_LIST_REQUEST
            : actionTypes.ORDERS_EDIT_ORDER_SUBSCRIPTION_RESPONSE
          ||
          actionTypes.ORDERS_EDIT_ORDER_SUBSCRIPTION_FAILURE
        ))
      )
    })
  );

export const fetchSelectedOrderAddressesEpic = (action$, state$) =>
  action$.pipe(
    ofType(actionTypes.ORDERS_GET_SELECTED_ORDER_ADDRESSES_REQUEST),
    mergeMap(action => {
      const { orderId } = action.payload
      return from(AirshopApi.get('orderSubscription/getOrderAddresses', {
      params: { orderId }
    }))
      .pipe(
        map(res => res.data.status === 'OK' ? res.data.items : err(errMessages.NOT_OK, res)),
        map(response => {
          const { selectedOrderAddresses_NEW_VERSION } = state$.value.orders
          const addresses = {
            ...selectedOrderAddresses_NEW_VERSION,
            [orderId]: response
          }
          return actions.ordersSelectedOrderAddressesResponse(addresses)
        }),
        catchError(error => of(actions.ordersSelectedOrderAddressesFailure(error))),
        takeUntil(action$.ofType(
          actionTypes.ORDERS_GET_SELECTED_ORDER_ADDRESSES_RESPONSE ||
          actionTypes.ORDERS_GET_SELECTED_ORDER_ADDRESSES_FAILURE
        ))
      )
    })
  );

export const setSelectedOrderAddressEpic = action$ =>
  action$.pipe(
    ofType(actionTypes.ORDERS_SET_SELECTED_ORDER_ADDRESS_REQUEST),
    mergeMap(action => {
      const { orderId, index } = action.payload
      return from(AirshopApi.get('orderSubscription/setOrderAddress', {
      params: { orderId, index }
    }))
      .pipe(
        map(res => res.data.status === 'OK' ? res.data.data : err(errMessages.NOT_OK, res)),
        map(response => actions.ordersSetSelectedOrderAddressResponse(
          response,
          actionTypes.ORDERS_SET_SELECTED_ORDER_ADDRESS_RESPONSE
        )),
        catchError(error => of(actions.ordersSetSelectedOrderAddressFailure(
          error,
          actionTypes.ORDERS_SET_SELECTED_ORDER_ADDRESS_FAILURE
        ))),
        takeUntil(action$.ofType(
          actionTypes.ORDERS_SET_SELECTED_ORDER_ADDRESS_RESPONSE ||
          actionTypes.ORDERS_SET_SELECTED_ORDER_ADDRESS_FAILURE
        ))
      )
    })
  );
